import { useQuery, useQueryClient } from "@tanstack/react-query";
import { VariantProps } from "class-variance-authority";
import {
  CircleHelp,
  Home,
  LayoutGrid,
  Folder,
  UsersRound,
  SquareUserRound,
  ChevronRight,
  Loader2,
  Save,
  FileImage,
  NotebookPen,
  Target,
  BookUser,
  Tags,
  SquareLibrary,
  ChevronDown,
  X,
  Plus,
  FileChartPie,
} from "lucide-react";
import React, {
  Children,
  isValidElement,
  Fragment,
  ReactNode,
  useMemo,
  useEffect,
} from "react";
import {
  Blocker,
  Link,
  NavLink,
  Outlet,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import {
  getPaidAdCreative,
  IMetaAdCreative,
  listPaidAdCreatives,
} from "../api/paid/meta/adcreatives.api";
import {
  getPaidAd,
  IMetaAd,
  listPaidAdsForAdset,
} from "../api/paid/meta/ads.api";
import {
  getPaidAdset,
  IMetaAdset,
  listPaidAdsetsForCampaign,
} from "../api/paid/meta/adsets.api";
import {
  getObjective,
  getPaidCampaign,
  PaidCampaignObjective,
  IMetaAdCampaign,
  listPaidCampaigns,
  checkMetaAccountStatus,
} from "../api/paid/meta/campaigns.api";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "../primitives/alert-dialog";
import { Button, buttonVariants } from "../primitives/button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "../primitives/command";
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from "../primitives/hover-card";
import {
  Popover,
  PopoverClose,
  PopoverContent,
  PopoverTrigger,
} from "../primitives/popover";
import { cn, parseBool, TooltipButton } from "../util/reusables";
import {
  listPaidForms,
  IMetaForm,
  getPaidForm,
} from "../api/paid/meta/forms.api";
import { removeHubPrefix, url } from "../routes";
import { UserAccount } from "../api/auth.api";
import { useSelector } from "react-redux";
import { RootState } from "../store";
import { getMetaAdLabels, MetaAdLabel } from "../api/paid/meta/adlabels.api";
import CheckAdAccount from "./components/CheckAdAccount";
import {
  IMetaSavedAudience,
  listSavedAudiences,
} from "../api/paid/meta/audiences.api";
import { Separator } from "../primitives/separator";
import { LocationTableDialog } from "../views/Hubs/components/Locations/LocationsTable";
import { convertPropertyToReadableFormat } from "../views/Admin/components/lib/data-table";
import capitalize from "lodash/capitalize";
import mindbodyIcon from "~/src/assets/MindbodyIcon.png";
export type PaidLayoutContext = {
  campaignsList: IMetaAdCampaign[];
  isLoadingCampaignsList: boolean;
  errorCampaignsList: Error;
  campaign: IMetaAdCampaign;
  isLoadingCampaign?: boolean;
  adsetsList: IMetaAdset[];
  isLoadingAdsetsList: boolean;
  errorAdsetsList: Error;
  adset: IMetaAdset;
  isLoadingAdset?: boolean;
  adsList: IMetaAd[];
  isLoadingAdsList: boolean;
  errorAdsList: Error;
  ad: IMetaAd;
  isLoadingAd?: boolean;
  adCreativesList: IMetaAdCreative[];
  isLoadingAdCreativesList: boolean;
  errorAdCreativesList: Error;
  adCreative: IMetaAdCreative;
  isLoadingAdCreative?: boolean;
  formsList: IMetaForm[];
  isLoadingFormsList: boolean;
  errorFormsList: Error;
  form: IMetaForm;
  isLoadingForm?: boolean;
  savedAudiencesList: IMetaSavedAudience[];
  isLoadingSavedAudiencesList: boolean;
  errorSavedAudiencesList: Error;
  adLabelsList: MetaAdLabel[];
  isLoadingAdLabelsList: boolean;
  errorAdLabelsList: Error;
};

export default function PaidLayout() {
  const user = useSelector((state: RootState) => state.user);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { campaignId, adsetId, adId, adCreativeId, formId } = useParams();

  // URL State Management
  const [searchParams, setSearchParams] = useSearchParams();
  const openLocationTableDialog = parseBool(searchParams.get("lt"));
  const setOpenLocationTableDialog = (open: boolean) => {
    setSearchParams((prev) => {
      if (open) {
        prev.set("lt", open.toString());
      } else {
        prev.delete("lt");
        prev.delete("tid");
        prev.delete("col");
      }
      return prev;
    });
  };
  const locationTableTeamId = searchParams.get("tid");
  const locationTableFocusedColumn = searchParams.get("col");

  const { data: accountStatus, isLoading: isLoadingAccountStatus } = useQuery({
    queryKey: ["metaAccountStatus", user?.hub?._id, user?.workspace?._id],
    queryFn: checkMetaAccountStatus,
  });

  // TODO: Remove this once we've rolled out paid to all users
  const checkPaidAccess = (checks: boolean) =>
    user?.manifest?.paid &&
    user?.module === "paid" &&
    !isLoadingAccountStatus &&
    accountStatus?.success &&
    checks;

  const queryClient = useQueryClient();

  /**
   * CAMPAIGNS
   */
  const {
    data: campaignsList = [],
    isLoading: isLoadingCampaignsList,
    error: errorCampaignsList,
  } = useQuery<IMetaAdCampaign[]>({
    queryKey: ["paid", "campaigns"],
    queryFn: () => listPaidCampaigns(),
    enabled: checkPaidAccess(true),
    retry: 1,
  });
  const { data: campaign, isLoading: isLoadingCampaign } = useQuery({
    queryKey: ["paid", "campaigns", campaignId],
    queryFn: () => getPaidCampaign(campaignId),
    enabled: checkPaidAccess(!!campaignId),
  });

  useEffect(() => {
    // Only prefetch if the user has paid access
    if (checkPaidAccess(true)) {
      queryClient.prefetchQuery({
        queryKey: ["paid", "campaigns"],
        queryFn: () => listPaidCampaigns(),
        staleTime: 5 * 60 * 1000,
      });
    }
  }, [queryClient, checkPaidAccess]);
  // ----------------------------------------------------------------

  /**
   * ADSETS
   */
  const {
    data: adsetsList = [],
    isLoading: isLoadingAdsetsList,
    error: errorAdsetsList,
  } = useQuery<IMetaAdset[]>({
    queryKey: [campaignId, "paid", "adsets"],
    queryFn: () => listPaidAdsetsForCampaign(campaign.id),
    enabled: checkPaidAccess(!!campaign?.id),
  });
  const { data: adset, isLoading: isLoadingAdset } = useQuery({
    queryKey: [campaignId, "paid", "adsets", adsetId],
    queryFn: () => getPaidAdset(adsetId),
    enabled: checkPaidAccess(!!adsetId),
  });
  // ----------------------------------------------------------------

  /**
   * ADS
   */
  const {
    data: adsList = [],
    isLoading: isLoadingAdsList,
    error: errorAdsList,
  } = useQuery<IMetaAd[]>({
    queryKey: [adsetId, "paid", "ads"],
    queryFn: () => listPaidAdsForAdset(adset.id),
    enabled: checkPaidAccess(!!adset?.id),
  });
  const { data: ad, isLoading: isLoadingAd } = useQuery({
    queryKey: [adsetId, "paid", "ads", adId],
    queryFn: () => getPaidAd(adId),
    enabled: checkPaidAccess(!!adId),
  });
  // ----------------------------------------------------------------

  /**
   * AD CREATIVES
   */
  const {
    data: adCreativesList = [],
    isLoading: isLoadingAdCreativesList,
    error: errorAdCreativesList,
  } = useQuery<IMetaAdCreative[]>({
    queryKey: ["paid", "adcreatives"],
    queryFn: () => listPaidAdCreatives(),
    enabled: checkPaidAccess(true),
  });

  const { data: adCreative, isLoading: isLoadingAdCreative } = useQuery({
    queryKey: ["paid", "adcreatives", adCreativeId],
    queryFn: () => getPaidAdCreative(adCreativeId),
    enabled: checkPaidAccess(!!adCreativeId),
  });
  // ----------------------------------------------------------------

  /**
   * LEADGEN FORMS
   */
  const {
    data: formsList = [],
    isLoading: isLoadingFormsList,
    error: errorFormsList,
  } = useQuery<IMetaForm[]>({
    queryKey: ["paid", "forms", user?.hub?._id ?? user?.workspace?._id],
    queryFn: () => listPaidForms(),
    enabled: checkPaidAccess(true),
  });
  const { data: form, isLoading: isLoadingForm } = useQuery({
    queryKey: ["paid", "forms", formId],
    queryFn: () => getPaidForm(formId),
    enabled: checkPaidAccess(!!formId),
  });
  // ----------------------------------------------------------------

  /**
   * SAVED AUDIENCES
   */
  const {
    data: savedAudiencesList = [],
    isLoading: isLoadingSavedAudiencesList,
    error: errorSavedAudiencesList,
  } = useQuery<IMetaSavedAudience[]>({
    queryKey: ["paid", "audiences", "saved"],
    queryFn: () => listSavedAudiences(),
    enabled: checkPaidAccess(true),
  });
  // ----------------------------------------------------------------

  /**
   * AD LABELS
   */
  const {
    data: adLabelsList = [],
    isLoading: isLoadingAdLabelsList,
    error: errorAdLabelsList,
  } = useQuery<MetaAdLabel[]>({
    queryKey: ["paid", "adlabels"],
    queryFn: () => getMetaAdLabels(),
    enabled: checkPaidAccess(true),
  });
  // ----------------------------------------------------------------

  // Redirect to the last page if there's a flamel-redirect session item, mostly for the `/socials` route
  useEffect(() => {
    const redirect = sessionStorage.getItem("flamel-redirect");
    if (redirect) {
      sessionStorage.removeItem("flamel-redirect");
      navigate(redirect);
    }
  }, []);

  const NavItems = useMemo(() => NAVIGATION_ITEMS(user), [user]);
  const FooterNavItems = useMemo(() => FOOTER_NAVIGATION_ITEMS(user), [user]);

  return (
    <CheckAdAccount user={user}>
      <style>
        {`
        :root {
          --main-background: #093C3E;
          --primary: 182 73.5% 13.3%;
          --primary-foreground: 0 0% 100%;
          --secondary: 182 30% 70%;
          --secondary-foreground: 0 0% 0%;
        }
        .dark {
          --primary: 182 73.5% 13.3%;
          --primary-foreground: 0 0% 100%;
          --secondary: 182 30% 10%;
          --secondary-foreground: 0 0% 100%;
        }
      `}
      </style>
      <div className="relative flex h-full max-h-full min-h-0 w-full overflow-hidden">
        {/* Sidebar */}
        <aside className="flex h-full max-h-full flex-col justify-between overflow-y-auto bg-gradient-to-b from-[#093C3E] to-[#0D1D0F]">
          <nav className="grid gap-1 p-2">
            {NavItems.map((item, index) => (
              <Fragment key={item.to}>
                <NavItem key={item.to} item={item} />
                {index === 1 && <Separator className="my-2" />}
              </Fragment>
            ))}
          </nav>
          <nav className="grid gap-1 p-2">
            {FooterNavItems.map((item, index) => (
              <Fragment key={item.to}>
                <NavItem key={item.to} item={item} />
                {index === 0 && <Separator className="my-2" />}
              </Fragment>
            ))}
          </nav>
        </aside>
        {/* Main Component */}
        <div className="flex h-full min-h-full w-full flex-col overflow-hidden rounded-tl-2xl bg-background">
          {/* Campaign, Ad Set, Ad - Navigation Menu */}
          {removeHubPrefix(pathname)?.startsWith("/paid/campaigns") ? (
            <div className="z-30 flex items-center gap-2 bg-muted p-2">
              <Popover>
                <PopoverTrigger asChild className="group">
                  <Button
                    variant="outline"
                    role="combobox"
                    className="flex max-w-[33%] items-center gap-2 bg-background"
                  >
                    {campaignId && (
                      <X
                        className="size-3"
                        onClick={(e) => {
                          e.stopPropagation();
                          navigate(
                            url(user, "/paid/campaigns", undefined, true),
                          );
                        }}
                      />
                    )}
                    <Folder className="h-4 w-4 flex-shrink-0" />
                    {campaign?.objective && (
                      <Objective
                        objective={campaign?.objective as PaidCampaignObjective}
                        isActive
                        side="bottom"
                      />
                    )}
                    <span className="truncate">
                      {campaign?.name || "Select Campaign..."}
                    </span>
                    <ChevronDown className="h-3 w-3 flex-shrink-0 opacity-50 transition-all group-data-[state=open]:rotate-180" />
                  </Button>
                </PopoverTrigger>
                <PopoverContent className="w-[300px] p-0" align="start">
                  <Command>
                    <CommandInput
                      placeholder="Search campaigns..."
                      className="h-9"
                    />
                    <CommandList>
                      <CommandEmpty>No campaigns found</CommandEmpty>
                      <CommandGroup className="flex flex-col gap-2">
                        <CommandItem
                          value="create-campaign"
                          onSelect={() => {
                            navigate(
                              url(
                                user,
                                "/paid/campaigns/create",
                                undefined,
                                true,
                              ),
                            );
                            document.dispatchEvent(
                              new KeyboardEvent("keydown", { key: "Escape" }),
                            );
                          }}
                          className="bg-primary text-primary-foreground hover:bg-primary/80 aria-selected:bg-primary/80 aria-selected:text-primary-foreground"
                        >
                          <span className="flex items-center gap-1 truncate text-xs font-semibold">
                            <Plus className="size-3" /> Create Campaign
                          </span>
                        </CommandItem>
                        {campaignsList?.map((campaign) => (
                          <CommandItem
                            key={campaign._id}
                            value={`${campaign._id} - ${campaign.name}`}
                            onSelect={() => {
                              navigate(
                                url(
                                  user,
                                  `/paid/campaigns/${campaign._id}`,
                                  undefined,
                                  true,
                                ),
                              );
                              document.dispatchEvent(
                                new KeyboardEvent("keydown", { key: "Escape" }),
                              );
                            }}
                            className="flex items-center justify-between"
                          >
                            <span className="w-auto truncate">
                              {campaign.name}
                            </span>
                            <Objective
                              objective={
                                campaign?.objective as PaidCampaignObjective
                              }
                              isActive
                              side="right"
                            />
                          </CommandItem>
                        ))}
                      </CommandGroup>
                    </CommandList>
                  </Command>
                </PopoverContent>
              </Popover>

              <Popover>
                <PopoverTrigger asChild className="group">
                  <Button
                    variant="outline"
                    role="combobox"
                    className="flex max-w-[33%] items-center gap-2 bg-background"
                    disabled={!campaignId}
                  >
                    {adsetId && (
                      <X
                        className="size-3"
                        onClick={(e) => {
                          e.stopPropagation();
                          navigate(
                            url(
                              user,
                              `/paid/campaigns/${campaignId}`,
                              undefined,
                              true,
                            ),
                          );
                        }}
                      />
                    )}
                    <LayoutGrid className="h-4 w-4 flex-shrink-0" />
                    <span className="truncate">
                      {adset?.name || "Select Ad Set..."}
                    </span>
                    <ChevronDown className="h-3 w-3 flex-shrink-0 opacity-50 transition-all group-data-[state=open]:rotate-180" />
                  </Button>
                </PopoverTrigger>
                <PopoverContent className="w-[300px] p-0" align="start">
                  <Command>
                    <CommandInput
                      placeholder="Search ad sets..."
                      className="h-9"
                    />
                    <CommandList>
                      <CommandEmpty>No ad sets found</CommandEmpty>
                      <CommandGroup>
                        <CommandItem
                          value="create-adset"
                          onSelect={() => {
                            navigate(
                              url(
                                user,
                                `/paid/campaigns/${campaignId}/adsets/create`,
                                undefined,
                                true,
                              ),
                            );
                            document.dispatchEvent(
                              new KeyboardEvent("keydown", { key: "Escape" }),
                            );
                          }}
                          className="bg-primary text-primary-foreground hover:bg-primary/80 aria-selected:bg-primary/80 aria-selected:text-primary-foreground"
                        >
                          <span className="flex items-center gap-1 truncate text-xs font-semibold">
                            <Plus className="size-3" /> Create{" "}
                            {user?.hub && "Parent "} Ad Set
                          </span>
                        </CommandItem>
                        {adsetsList?.map((adset) => (
                          <CommandItem
                            key={adset._id}
                            value={`${adset._id} - ${adset.name}`}
                            onSelect={() => {
                              navigate(
                                url(
                                  user,
                                  `/paid/campaigns/${campaignId}/adsets/${adset._id}`,
                                  undefined,
                                  true,
                                ),
                              );
                              document.dispatchEvent(
                                new KeyboardEvent("keydown", { key: "Escape" }),
                              );
                            }}
                          >
                            <span className="truncate">{adset.name}</span>
                          </CommandItem>
                        ))}
                      </CommandGroup>
                    </CommandList>
                  </Command>
                </PopoverContent>
              </Popover>

              <Popover>
                <PopoverTrigger asChild className="group">
                  <Button
                    variant="outline"
                    role="combobox"
                    className="flex max-w-[33%] items-center gap-2 bg-background"
                    disabled={!adsetId}
                  >
                    {ad?.name && (
                      <X
                        className="size-3"
                        onClick={(e) => {
                          e.stopPropagation();
                          navigate(
                            url(
                              user,
                              `/paid/campaigns/${campaignId}/adsets/${adsetId}`,
                              undefined,
                              true,
                            ),
                          );
                        }}
                      />
                    )}
                    <FileImage className="h-4 w-4 flex-shrink-0" />
                    <span className="truncate">
                      {ad?.name || "Select Ad..."}
                    </span>
                    <ChevronDown className="h-3 w-3 flex-shrink-0 opacity-50 transition-all group-data-[state=open]:rotate-180" />
                  </Button>
                </PopoverTrigger>
                <PopoverContent className="w-[300px] p-0" align="start">
                  <Command>
                    <CommandInput placeholder="Search ads..." className="h-9" />
                    <CommandList>
                      <CommandEmpty>No ads found</CommandEmpty>
                      <CommandGroup>
                        <CommandItem
                          value="create-ad"
                          onSelect={() => {
                            navigate(
                              url(
                                user,
                                `/paid/campaigns/${campaignId}/adsets/${adsetId}/ads/create`,
                                undefined,
                                true,
                              ),
                            );
                            document.dispatchEvent(
                              new KeyboardEvent("keydown", { key: "Escape" }),
                            );
                          }}
                          className="bg-primary text-primary-foreground hover:bg-primary/80 aria-selected:bg-primary/80 aria-selected:text-primary-foreground"
                        >
                          <span className="flex items-center gap-1 truncate text-xs font-semibold">
                            <Plus className="size-3" /> Create{" "}
                            {user?.hub && "Parent "} Ad
                          </span>
                        </CommandItem>
                        {adsList?.map((ad) => (
                          <CommandItem
                            key={ad._id}
                            value={`${ad._id} - ${ad.name}`}
                            onSelect={() => {
                              navigate(
                                url(
                                  user,
                                  `/paid/campaigns/${campaignId}/adsets/${adsetId}/ads/${ad._id}`,
                                  undefined,
                                  true,
                                ),
                              );
                              document.dispatchEvent(
                                new KeyboardEvent("keydown", { key: "Escape" }),
                              );
                            }}
                          >
                            <span className="truncate">{ad.name}</span>
                          </CommandItem>
                        ))}
                      </CommandGroup>
                    </CommandList>
                  </Command>
                </PopoverContent>
              </Popover>
            </div>
          ) : (
            removeHubPrefix(pathname) !== "/paid" &&
            !removeHubPrefix(pathname).startsWith("/paid/mindbody") &&
            !FooterNavItems.some((item) => item.isActive(pathname)) && (
              <div className="z-10 border bg-muted p-1 shadow-sm">
                <Breadcrumbs>
                  {/* AD CREATIVES */}
                  {removeHubPrefix(pathname)?.startsWith(
                    "/paid/adcreatives",
                  ) && (
                    <>
                      <BreadcrumbItem
                        to={url(user, "/paid/adcreatives", undefined, true)}
                      >
                        <div className="flex items-center gap-2">
                          <FileImage className="size-4" />
                          Ad Creatives
                        </div>
                      </BreadcrumbItem>
                      {removeHubPrefix(pathname) ===
                      "/paid/adcreatives/create" ? (
                        <BreadcrumbItem
                          to={url(
                            user,
                            "/paid/adcreatives/create",
                            undefined,
                            true,
                          )}
                        >
                          <div className="flex items-center gap-2">
                            Create Ad Creative
                          </div>
                        </BreadcrumbItem>
                      ) : (
                        adCreativeId && (
                          <BreadcrumbItem
                            to={url(
                              user,
                              `/paid/adcreatives/${adCreativeId}`,
                              undefined,
                              true,
                            )}
                          >
                            <Popover>
                              <PopoverTrigger className="flex items-center gap-2">
                                {adCreative?.name || "Loading..."}
                              </PopoverTrigger>
                              <PopoverContent
                                className="relative z-30 w-80 p-0"
                                align="start"
                                alignOffset={-12}
                              >
                                <Command className="bg-muted">
                                  <CommandInput
                                    className="h-8 rounded-full border bg-background text-xs font-normal text-muted-foreground"
                                    placeholder="Search ad creatives..."
                                  />
                                  <CommandList className="bg-background font-normal">
                                    <CommandEmpty>
                                      No ad creatives found.
                                    </CommandEmpty>
                                    {adCreativesList?.map((adCreative) => (
                                      <PopoverClose
                                        className="w-full"
                                        key={adCreative._id}
                                      >
                                        <CommandItem
                                          onSelect={() =>
                                            navigate(
                                              `/paid/adcreatives/${adCreative._id}`,
                                            )
                                          }
                                          className="text-sm"
                                        >
                                          {adCreative.name}
                                        </CommandItem>
                                      </PopoverClose>
                                    ))}
                                  </CommandList>
                                </Command>
                              </PopoverContent>
                            </Popover>
                          </BreadcrumbItem>
                        )
                      )}
                    </>
                  )}

                  {/* FORMS */}
                  {removeHubPrefix(pathname)?.startsWith("/paid/forms") && (
                    <Fragment>
                      <BreadcrumbItem
                        to={url(user, "/paid/forms", undefined, true)}
                      >
                        <div className="flex items-center gap-2">
                          <NotebookPen className="size-4" />
                          Instant Forms
                        </div>
                      </BreadcrumbItem>
                      {removeHubPrefix(pathname) === "/paid/forms/create" ? (
                        <BreadcrumbItem
                          to={url(user, "/paid/forms/create", undefined, true)}
                        >
                          <div className="flex items-center gap-2">
                            Create Instant Form
                          </div>
                        </BreadcrumbItem>
                      ) : (
                        formId && (
                          <Fragment>
                            <BreadcrumbItem
                              to={url(
                                user,
                                `/paid/forms/${formId}`,
                                undefined,
                                true,
                              )}
                            >
                              <Popover>
                                <PopoverTrigger className="flex items-center gap-2">
                                  {form?.name || "Loading..."}
                                </PopoverTrigger>
                                <PopoverContent
                                  className="relative z-30 w-80 p-0"
                                  align="start"
                                  alignOffset={-12}
                                >
                                  <Command className="bg-muted">
                                    <CommandInput
                                      className="h-8 rounded-full border bg-background text-xs font-normal text-muted-foreground"
                                      placeholder="Search forms..."
                                    />
                                    <CommandList className="bg-background font-normal">
                                      <CommandEmpty>
                                        No forms found.
                                      </CommandEmpty>
                                      {formsList?.map((form) => (
                                        <PopoverClose
                                          className="w-full"
                                          key={form.id}
                                        >
                                          <CommandItem
                                            onSelect={() =>
                                              navigate(`/paid/forms/${form.id}`)
                                            }
                                            className="text-sm"
                                          >
                                            {form.name}
                                          </CommandItem>
                                        </PopoverClose>
                                      ))}
                                    </CommandList>
                                  </Command>
                                </PopoverContent>
                              </Popover>
                            </BreadcrumbItem>
                            {pathname.endsWith("/leads") && (
                              <BreadcrumbItem
                                to={url(
                                  user,
                                  `/paid/forms/${formId}/leads`,
                                  undefined,
                                  true,
                                )}
                              >
                                Leads
                              </BreadcrumbItem>
                            )}
                          </Fragment>
                        )
                      )}
                    </Fragment>
                  )}

                  {/* TARGETING */}
                  {removeHubPrefix(pathname).startsWith("/paid/targeting") && (
                    <Fragment>
                      <BreadcrumbItem
                        to={url(user, "/paid/targeting", undefined, true)}
                      >
                        <div className="flex items-center gap-2">
                          <Target className="size-4" />
                          Targeting
                        </div>
                      </BreadcrumbItem>
                    </Fragment>
                  )}

                  {/* AUDIENCES */}
                  {removeHubPrefix(pathname).startsWith("/paid/audiences") && (
                    <Fragment>
                      <BreadcrumbItem
                        to={url(user, "/paid/audiences", undefined, true)}
                      >
                        <div className="flex items-center gap-2">
                          <BookUser className="size-4" />
                          Audiences
                        </div>
                      </BreadcrumbItem>
                    </Fragment>
                  )}

                  {/* AD LABELS */}
                  {removeHubPrefix(pathname).startsWith("/paid/adlabels") && (
                    <Fragment>
                      <BreadcrumbItem
                        to={url(user, "/paid/adlabels", undefined, true)}
                      >
                        <div className="flex items-center gap-2">
                          <Tags className="size-4" />
                          Ad Labels
                        </div>
                      </BreadcrumbItem>
                    </Fragment>
                  )}
                </Breadcrumbs>
              </div>
            )
          )}
          <Outlet
            context={
              {
                campaignsList,
                isLoadingCampaignsList,
                errorCampaignsList,
                campaign,
                isLoadingCampaign,
                adsetsList,
                isLoadingAdsetsList,
                errorAdsetsList,
                adset,
                isLoadingAdset,
                adsList,
                isLoadingAdsList,
                errorAdsList,
                ad,
                isLoadingAd,
                adCreativesList,
                isLoadingAdCreativesList,
                errorAdCreativesList,
                adCreative,
                isLoadingAdCreative,
                formsList,
                isLoadingFormsList,
                errorFormsList,
                form,
                isLoadingForm,
                savedAudiencesList,
                isLoadingSavedAudiencesList,
                errorSavedAudiencesList,
                adLabelsList,
                isLoadingAdLabelsList,
                errorAdLabelsList,
              } as PaidLayoutContext
            }
          />
        </div>
      </div>
      <LocationTableDialog
        open={openLocationTableDialog}
        onOpenChange={setOpenLocationTableDialog}
        focusLocationId={locationTableTeamId}
        focusColumnKey={locationTableFocusedColumn}
      />
    </CheckAdAccount>
  );
}

type NavItem = {
  icon: React.ComponentType<{ className?: string }>;
  label: string;
  to: string;
  isActive: (pathname: string) => boolean;
  disabled?: boolean;
} & VariantProps<typeof buttonVariants>;

const NAVIGATION_ITEMS = (user: UserAccount): NavItem[] => [
  {
    icon: Home,
    label: "Under Construction... 🚧",
    to: url(user, "/paid", undefined, true),
    variant: "outlinePaid",
    isActive: (pathname) => removeHubPrefix(pathname) === "/paid",
    disabled: true,
  },
  {
    icon: SquareLibrary,
    label: "Campaigns",
    to: url(user, "/paid/campaigns", undefined, true),
    isActive: (pathname) =>
      removeHubPrefix(pathname).startsWith("/paid/campaigns"),
  },
  {
    icon: FileChartPie,
    // label: "Insights",
    label: "Under Construction... 🚧",
    to: url(user, "/paid/insights", undefined, true),
    isActive: (pathname) => removeHubPrefix(pathname) === "/paid/insights",
    disabled: true,
  },
  // {
  //   icon: FileImage,
  //   label: "Ad Creatives",
  //   to: url(user, "/paid/adcreatives", undefined, true),
  //   isActive: (pathname) =>
  //     removeHubPrefix(pathname).startsWith("/paid/adcreatives"),
  // },
  {
    icon: NotebookPen,
    label: "Instant Forms",
    to: url(user, "/paid/forms", undefined, true),
    isActive: (pathname) => removeHubPrefix(pathname).startsWith("/paid/forms"),
  },
  // {
  //   icon: Target,
  //   label: "Targeting",
  //   to: url(user, "/paid/targeting", undefined, true),
  //   isActive: (pathname) =>
  //     removeHubPrefix(pathname).startsWith("/paid/targeting"),
  // },
  {
    icon: BookUser,
    label: "Audiences",
    to: url(user, "/paid/audiences", undefined, true),
    isActive: (pathname) =>
      removeHubPrefix(pathname).startsWith("/paid/audiences"),
  },
  {
    icon: () => (
      <img
        crossOrigin="anonymous"
        src={mindbodyIcon}
        alt=""
        className="size-6 object-cover object-center"
      />
    ),
    label: "Mindbody",
    to: url(user, "/paid/mindbody", undefined, true),
    isActive: (pathname) =>
      removeHubPrefix(pathname).startsWith("/paid/mindbody"),
  },
  // {
  //   icon: Tags,
  //   label: "Ad Labels",
  //   to: url(user, "/paid/adlabels", undefined, true),
  //   isActive: (pathname) =>
  //     removeHubPrefix(pathname).startsWith("/paid/adlabels"),
  // },
];

const FOOTER_NAVIGATION_ITEMS = (user: UserAccount): NavItem[] => [
  {
    icon: UsersRound,
    label: "Social Accounts",
    to: url(user, "/socials"),
    isActive: (pathname) => removeHubPrefix(pathname) === "/socials",
  },
  {
    icon: SquareUserRound,
    label: "Profile",
    to: url(user, "/profile"),
    isActive: (pathname) => removeHubPrefix(pathname) === "/profile",
  },
  {
    icon: CircleHelp,
    label: "Help",
    to: url(user, "/help"),
    isActive: (pathname) => removeHubPrefix(pathname) === "/help",
  },
];

function NavItem({ item }: { item: NavItem }) {
  const { pathname } = useLocation();
  const isActive = useMemo(() => item.isActive(pathname), [item, pathname]);

  return (
    <NavLink
      to={item.to}
      className={cn(
        "rounded-lg transition-all hover:bg-muted",
        isActive &&
          "hover:text-[#C5E99F][&>*>*]:border-transparent rounded-full bg-background/10 text-[#C5E99F] hover:hover:bg-[#0D4949]/80 [&>*]:border-transparent",
      )}
    >
      <TooltipButton aria-label={item.label} text={item.label} side="right">
        <Button
          variant={item.variant ?? "ghostDark"}
          disabled={item.disabled}
          size="icon"
          className={cn(
            "transition-all hover:hover:bg-[#0D4949]/80",
            isActive && "rounded-full text-[#C5E99F]",
          )}
        >
          <item.icon
            className={cn(
              "size-5 transition-all",
              isActive ? "text-[#C5E99F]" : "text-muted",
            )}
          />
        </Button>
      </TooltipButton>
    </NavLink>
  );
}
interface BreadcrumbItemProps {
  to: string;
  isActive?: boolean;
  children: ReactNode;
}

function BreadcrumbItem({ to, isActive, children }: BreadcrumbItemProps) {
  const content = (
    <div className="flex items-center gap-2">
      {React.Children.map(children, (child) =>
        React.isValidElement(child) && child.type === Objective
          ? //@ts-ignore
            React.cloneElement(child, { isActive })
          : child,
      )}
    </div>
  );

  return (
    <li className="inline-flex items-center">
      {isActive ? (
        <h1 className="ml-1 flex items-center gap-2 rounded-md bg-primary px-3 py-1 text-sm font-semibold text-primary-foreground">
          {content}
        </h1>
      ) : (
        <Link
          to={to}
          className={cn(
            "ml-1 inline-flex items-center gap-2 rounded-md px-3 py-1 text-sm font-medium",
            "text-muted-foreground transition-colors hover:bg-accent hover:text-accent-foreground",
          )}
        >
          {content}
        </Link>
      )}
    </li>
  );
}
interface BreadcrumbsProps {
  children: ReactNode;
}

function Breadcrumbs({ children }: BreadcrumbsProps) {
  const flattenChildren = (children: React.ReactNode): React.ReactElement[] => {
    return Children.toArray(children).reduce(
      (acc: React.ReactElement[], child) => {
        if (isValidElement(child)) {
          if (child.type === Fragment) {
            return acc.concat(flattenChildren((child.props as any).children));
          }
          if (child.type === BreadcrumbItem) {
            return acc.concat(child);
          }
        }
        return acc;
      },
      [],
    ) as any;
  };

  const items = flattenChildren(children);

  return (
    <nav className="flex" aria-label="Breadcrumb">
      <ol className="inline-flex items-center">
        {items.map((item, index) => (
          <React.Fragment key={index}>
            {index > 0 && (
              <ChevronRight className="h-4 w-4 text-muted-foreground" />
            )}
            {React.cloneElement(item, {
              isActive: index === items.length - 1,
            } as any)}
          </React.Fragment>
        ))}
      </ol>
    </nav>
  );
}

export function Objective({
  objective,
  isActive,
  side,
  className,
}: {
  objective?: PaidCampaignObjective;
  isActive?: boolean;
  side?: "left" | "right" | "top" | "bottom";
  className?: string;
}) {
  const campaignObjective = useMemo(() => getObjective(objective), [objective]);

  if (!campaignObjective)
    return (
      <span className="text-xs text-muted-foreground">
        {capitalize(convertPropertyToReadableFormat(objective))}
      </span>
    );

  return (
    <HoverCard>
      <HoverCardTrigger asChild>
        <span
          className={cn(
            "inline-flex cursor-help items-center rounded-md px-2 py-0.5 text-2xs font-medium",
            campaignObjective.color,
            isActive && "text-primary",
            className,
          )}
        >
          <campaignObjective.icon className="mr-1 size-2.5" />
          {campaignObjective.label}
        </span>
      </HoverCardTrigger>
      <HoverCardContent className="w-80 p-3" side={side}>
        <p className="text-sm font-medium">
          {campaignObjective.shortDescription}
        </p>
      </HoverCardContent>
    </HoverCard>
  );
}

export function UnsavedChangesDialog({
  blocker,
  onSave,
  isPending,
}: {
  blocker: Blocker;
  onSave?: () => void | Promise<void>;
  isPending?: boolean;
}) {
  return (
    <AlertDialog open={blocker.state === "blocked"}>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Unsaved Changes</AlertDialogTitle>
          <AlertDialogDescription>
            You have unsaved changes. Are you sure you want to proceed without
            saving?
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter className="sm:justify-between">
          {onSave ? (
            <Button
              variant="constructive"
              onClick={() => onSave()}
              disabled={isPending}
            >
              {isPending ? (
                <Loader2 className="mr-2 h-4 w-4 animate-spin" />
              ) : (
                <Save className="mr-2 h-4 w-4" />
              )}
              Save and Proceed
            </Button>
          ) : (
            <div></div>
          )}
          <div className="flex items-center gap-2">
            <AlertDialogCancel onClick={() => blocker.reset()}>
              Cancel
            </AlertDialogCancel>
            <AlertDialogAction onClick={() => blocker.proceed()}>
              Proceed
            </AlertDialogAction>
          </div>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}
