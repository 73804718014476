import { ObjectValues } from "../util/reusables";
import { PlatformType } from "~/src/util/platforms";
import { Role, UserAccount } from "./auth.api";
import { Campaign } from "./campaign.api";
import { Hub } from "./hub.api";
import { Media } from "./media.api";
import { PortfolioItem } from "./portfolio.api";
import { Template } from "./template.api";
import { OrganizationType } from "./organization.api";
import { BrandType } from "./brand.api";
import axios from "axios";
import { IPseudostructures } from "./foldering.api";
import { Location } from "./locations.api";
import { MindbodyTeamIntegration } from "../hooks/use-mindbody";

export interface ShallowUser {
  _id: string;
  pfp: string;
  firstname: string;
  lastname: string;
  email: string;
}

export type LuminIntegration = {
  locationId: string;
};

export interface Team {
  _id?: string;
  owner?: string | Partial<UserAccount>;
  users?: string[];
  approvalWorkflow?: string;
  campaigns?: string[] | Campaign[];
  organization?: OrganizationType;
  name: string;
  hubs?: string[] | Hub[];
  primaryHub?: string | Hub;
  // Associated Assets
  media?: string[] | Media[];
  portfolioItems?: string[] | PortfolioItem[];
  postTemplates?: string[] | Template[];
  snapshots?: TeamSnapshot[];
  // Integrations
  twitter?: {
    id?: string;
    key: string;
    secret: string;
    name?: string;
    handle?: string;
    pfp?: string;
  };
  linkedin?: {
    key: string;
    name?: string;
    id?: string;
    pfp?: string;
    orgs: Array<{
      name: string;
      id: number;
      pfp?: string;
      urn: string;
    }>;
    selectedAccount: number;
    lastIngestTimestamp?: number;
  };
  instagram?: Record<string, any>;
  facebook?: Record<string, any>;
  mindbody?: MindbodyTeamIntegration;
  lumin?: LuminIntegration;
  expiredConnections?: string[];
  ingestedProfiles?: Record<PlatformType, Record<string, number>>;
  metadata?: {
    postsFeatureDialogDismissed?: boolean;
  };
  pseudostructure?: IPseudostructures;
  flags?: string[];
  brand?: BrandType;
  icon?: string | Media;
  location?: Location;
  requiresOnboarding?: boolean;

  /**
   * Tracking fields for Meta Ads syncing
   */
  selectedMetaAdAccountId?: string;
  paidMetaAdCampaignsLastSyncTimestamp?: number;
  paidMetaAdCreativesLastSyncTimestamp?: number;
  paidMetaAdFormsLastSyncTimestamp?: number;
  paidMetaCustomAudiencesLastSyncTimestamp?: number;
  paidMetaSavedAudiencesLastSyncTimestamp?: number;
  paidMetaAdLabelsLastSyncTimestamp?: number;
}

export interface PlatformSnapshot {
  impressions: number;
  engagements: number;
  engagementRate: number;
  numPosts: number;
  followers: number;
  changeInFollowers?: number;
  bestStreak?: number;
  currentStreak?: number;
}

export interface TeamSnapshot {
  _id?: string;
  createdAt: string;
  team: Team;
  organization: Partial<OrganizationType>;
  previous?: TeamSnapshot;
  period: "week" | "month";
  instagram?: PlatformSnapshot;
  facebook?: PlatformSnapshot;
  linkedin?: PlatformSnapshot;
  cumulative?: PlatformSnapshot;
}

// TODO: Create FE/BE shared Types
export const MembershipRole = {
  Owner: "Owner",
  SuperAdmin: "Super Admin",
  Admin: "Admin",
  Editor: "Editor",
  Commenter: "Commenter",
} as const;
export type MembershipRole = ObjectValues<typeof MembershipRole>;

export type Invite = {
  email: string;
  role: MembershipRole;
};
export type Membership = {
  userId: string;
  role: MembershipRole;
};

export type MemberDetails = ShallowUser & Pick<Membership, "role">;

const getTeams = () => fetch(`/api/teams`);
const getTeamBrand = async (teamId: string) => {
  const res = await axios(`/api/teams/${teamId}/brand`);
  return res.data;
};
export const updateCurrentWorkspace = (diff: Partial<Team>) =>
  fetch(`/api/teams`, {
    method: "PATCH",
    body: JSON.stringify({
      ...diff,
    }),
    headers: {
      "Content-Type": "application/json",
    },
  });
const deleteTeam = (teamId: string) =>
  fetch(`/api/teams/${teamId}`, {
    method: "DELETE",
  });

export interface WorkspaceManagement {
  _id: string;
  name: string;
  users: Array<{
    _id: string;
    firstname: string;
    lastname: string;
    email: string;
    pfp: string;
    organizationRole: Role;
  }>;
  approvalWorkflow: {
    _id: string;
    name: string;
  } | null;
  organization: {
    _id: string;
    name: string;
    owner: {
      _id: string;
      firstname: string;
      lastname: string;
      email: string;
      pfp: string;
      organizationRole: Role;
      defaultTeam: string;
    };
    seats: Array<{
      _id: string;
      firstname: string;
      lastname: string;
      email: string;
      pfp: string;
      organizationRole: Role;
    }>;
    approvalWorkflows: Array<{
      _id: string;
      name: string;
    }>;
  };
}
const getWorkspaceManagement = async (
  workspaceId: string,
): Promise<WorkspaceManagement> => {
  try {
    const response = await axios.get<WorkspaceManagement>(
      `/api/teams/${workspaceId}/manage`,
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching workspace management:", error);
    throw error;
  }
};

interface AdAccount {
  account_id: string;
  [key: string]: any;
}

export const getCurrentWorkspaceAdAccounts = async (): Promise<AdAccount[]> => {
  const response = await axios.get<AdAccount[]>(`/api/teams/adaccounts`);
  return response.data;
};

export interface TeamAPI {
  getTeams: () => Promise<Response>;
  getTeamBrand: (teamId: string) => Promise<any>;
  deleteTeam: (teamId: string) => Promise<Response>;
  getWorkspaceManagement: (workspaceId: string) => Promise<WorkspaceManagement>;
}

export default {
  getTeams,
  getTeamBrand,
  deleteTeam,
  getWorkspaceManagement,
} as TeamAPI;
