import axios from "axios";
import dayjs from "../util/dayjs";
import { Media } from "./media.api";
import { PortfolioItem } from "./portfolio.api";

export interface MediaCheckDetail {
  id: string;
  score: string;
  description: string;
}

export type BrandCheckIssue =
  | "Logo Usage"
  | "Tone & Messaging"
  | "Imagery"
  | "Compliance";

export interface BrandCheckResponse {
  portfolioItemId: string;
  brandCheckScore: number;
  brandCheckDescription: string;
  brandCheckDetails: string;
  brandCheckRecommendations: string[];
  mediaCheckScore: number;
  mediaCheckDescription: string;
  mediaCheckDetails: MediaCheckDetail[];
  mediaCheckRecommendations: string[];
  captionCheckScore: number;
  captionCheckDescription: string;
  captionCheckRecommendations: string[];
  flaggedIssues?: {
    type: BrandCheckIssue;
    description: string;
  }[];
}

export interface BrandCheck extends BrandCheckResponse {
  _id: string;
  status: "pending" | "approved" | "rejected";
  approvedBy?: {
    _id: string;
    firstname: string;
    lastname: string;
    email: string;
    pfp?: string;
  };
  rejectedBy?: {
    _id: string;
    firstname: string;
    lastname: string;
    email: string;
    pfp?: string;
  };
  approvalDate?: string;
  rejectionDate?: string;
  approvalNotes?: string;
  comments?: Array<{
    _id: string;
    user: {
      _id: string;
      firstname: string;
      lastname: string;
      email: string;
      pfp?: string;
    };
    comment: string;
    createdAt: string;
    updatedAt: string;
  }>;
  createdAt: string;
  updatedAt: string;
}

export interface PortfolioItemWithBrandChecks
  extends Omit<PortfolioItem, "team" | "media"> {
  _id: string;
  brandChecks: BrandCheck[];
  team: {
    _id: string;
    name: string;
  };
  media: Media[];
  createdAt: string;
  updatedAt: string;
}

// Create a new brand check
export const createBrandCheck = async (
  portfolioItemId: string,
): Promise<BrandCheck> => {
  const response = await axios.post(`/api/brandcheck/${portfolioItemId}/check`);
  return response.data;
};

// Get a portfolio item with brand checks populated
export const getPortfolioItemWithBrandChecks = async (
  portfolioItemId: string,
): Promise<PortfolioItemWithBrandChecks> => {
  const response = await axios.get(
    `/api/brandcheck/portfolio/${portfolioItemId}`,
  );
  return response.data;
};

// Get all brand checks by portfolio item id
export const getBrandChecksByPortfolioId = async (
  portfolioItemId: string,
): Promise<PortfolioItemWithBrandChecks> => {
  const response = await axios.get(`/api/brandcheck/${portfolioItemId}/check`);
  return response.data;
};

// Approve a brand check
export const approveBrandCheck = async (
  brandCheckId: string,
  notes?: string,
): Promise<{ message: string }> => {
  const response = await axios.post(`/api/brandcheck/${brandCheckId}/approve`, {
    notes,
  });
  return response.data;
};

// Reject a brand check
export const rejectBrandCheck = async (
  brandCheckId: string,
  notes?: string,
): Promise<{ message: string }> => {
  const response = await axios.post(`/api/brandcheck/${brandCheckId}/reject`, {
    notes,
  });
  return response.data;
};

// Add a comment to a brand check
export const addCommentToBrandCheck = async (
  brandCheckId: string,
  comment: string,
): Promise<any> => {
  const response = await axios.post(`/api/brandcheck/${brandCheckId}/comment`, {
    comment,
  });
  return response.data;
};

// Get upcoming brand checks for larval posts (from today to end of month)
export const getUpcomingBrandChecks = async (): Promise<
  PortfolioItemWithBrandChecks[]
> => {
  const today = dayjs();
  const endOfMonth = today.endOf("month");

  const response = await axios.get("/api/brandcheck/upcoming", {
    params: {
      from: today.valueOf(),
      to: endOfMonth.valueOf(),
    },
  });
  return response.data;
};
