import { mergeAttributes, Node } from "@tiptap/core";

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    variable: {
      insertVariable: (variable: string) => ReturnType;
    };
  }
}

export interface VariableOptions {
  HTMLAttributes: Record<string, any>;
}

export const Variable = Node.create<VariableOptions>({
  name: "variable",

  inline: true,
  group: "inline",
  selectable: false,
  atom: true,

  addOptions() {
    return {
      HTMLAttributes: {
        class: "dynamic-variable",
      },
    };
  },

  addAttributes() {
    return {
      variable: {
        default: null,
        parseHTML: (element) => element.getAttribute("data-variable"),
        renderHTML: (attributes) => ({
          "data-variable": attributes.variable,
        }),
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: "span[data-variable]",
      },
    ];
  },

  renderHTML({ node, HTMLAttributes }) {
    return [
      "span",
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes),
      `{{${node.attrs.variable}}}`,
    ];
  },

  addCommands() {
    return {
      insertVariable:
        (variable: string) =>
        ({ commands }) => {
          return commands.insertContent({
            type: this.name,
            attrs: { variable },
          });
        },
    };
  },
});
