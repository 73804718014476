import React from "react";
import { FaGlobeAmericas, FaThumbsUp } from "react-icons/fa";
import { BsPatchCheckFill } from "react-icons/bs";
import LoadMedia from "../../Load/LoadMedia";
import { cn, pluralize, username, withPfp } from "~/src/util/reusables";
import { RootState } from "~/src/store";
import { useSelector } from "react-redux";
import { getActiveAccountForPlatform } from "~/src/views/CommunityManagement/lib/utils";
import { PlatformType } from "~/src/util/platforms";
import dayjs from "~/src/util/dayjs";
import type { Team } from "~/src/api/team.api";
import ViewOnlyCaptionWithFallback from "../../../views/Post/components/editors/ViewOnlyCaptionWithFallback";
import { LocationAccountInfo } from "../../../api/locations.api";

export interface FacebookTemplateProps {
  className?: string;
  display?: React.ReactNode;
  media?: string;
  mimeType?: string;
  caption: string;
  selected?: boolean;
  when?: dayjs.Dayjs;
  onSelectAccount?: () => void;
  raw?: any;
  forceWorkspace?: Team;
  likeCount?: number;
  forceDisplayPfp?: string;
  forceDisplayName?: string;
  parentPostId?: string;
  account?: LocationAccountInfo;
}

const FacebookTemplate = ({
  className,
  display,
  media,
  mimeType,
  caption,
  selected,
  when,
  onSelectAccount,
  raw,
  forceWorkspace,
  likeCount,
  forceDisplayPfp,
  forceDisplayName,
  parentPostId,
  account,
}: FacebookTemplateProps) => {
  const user = useSelector((state: RootState) => state.user);
  const { name, pfp } = getActiveAccountForPlatform(
    forceWorkspace ?? user?.workspace,
    PlatformType.Facebook,
  );

  const { created_time, from, totalComments, shares } = raw ?? {};
  const { id: userId, name: dynamicName } = from ?? {};

  if (!when && created_time) when = dayjs(created_time);
  const dynamicPfp =
    !!userId && `https://graph.facebook.com/v19.0/${userId}/picture`;

  return (
    <div className={cn("w-full rounded-lg bg-background", className)}>
      <div className="p-3">
        <div className="flex items-center" onClick={onSelectAccount}>
          <img
            crossOrigin="anonymous"
            src={withPfp(
              forceDisplayName ? forceDisplayPfp : dynamicPfp || pfp,
              forceDisplayName ?? dynamicName ?? name ?? username(user),
            )}
            alt=""
            className="size-8 rounded-full"
          />
          <div className="ml-2">
            <div className="text-[14px] font-semibold text-[#050505]">
              {forceDisplayName ??
                dynamicName ??
                name ??
                (user?.firstname || user?.lastname
                  ? username(user)
                  : "example")}
            </div>
            <div className="flex items-center text-[12px] text-[#65676B]">
              <span>
                {when
                  ? when.year() === dayjs().year()
                    ? when.format("MMMM D [at] h:mm A")
                    : when.format("MMMM D, YYYY [at] h:mm A")
                  : "Just Now"}
              </span>
              <span className="mx-1">•</span>
              <FaGlobeAmericas className="text-xs" />
            </div>
          </div>
        </div>
        <ViewOnlyCaptionWithFallback
          platform={PlatformType.Facebook}
          stringContent={caption}
          className="mt-2 text-sm text-[#050505]"
          account={account}
        />
      </div>
      {display && <div className="w-full">{display}</div>}
      {!display && media && (
        <LoadMedia mimeType={mimeType} src={media} className="w-full" />
      )}
      {!!parentPostId && (
        <div
          className="mx-3 my-2 cursor-pointer rounded border border-gray-300 bg-gray-50 p-2 text-center text-xs text-[#65676B] transition-colors hover:bg-gray-100"
          onClick={() =>
            window.open(
              `https://www.facebook.com/${parentPostId}`,
              "_blank",
              "noopener,noreferrer",
            )
          }
        >
          Repost. See the original post...
        </div>
      )}
      <div className="px-3 py-1">
        <div className="flex justify-between text-xs text-[#65676B]">
          {Boolean(likeCount) && (
            <div className="flex items-center">
              <FaThumbsUp className="mr-1 text-[#1877F2]" />
              {pluralize(likeCount, "reaction")}
            </div>
          )}
          <div className="flex space-x-2">
            {Boolean(totalComments) && (
              <span>{pluralize(totalComments, "comment")}</span>
            )}
            {Boolean(shares) && <span>{pluralize(shares, "share")}</span>}
          </div>
        </div>
      </div>
      <BsPatchCheckFill
        width={40}
        height={40}
        className={cn(
          "absolute right-2 top-2 text-[#1877F2]",
          selected ? "" : "hidden",
        )}
      />
    </div>
  );
};

export default FacebookTemplate;
