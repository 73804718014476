import React from "react";
import ColorSwatch from "./ColorSwatch";
import { detectHexCodes } from "./utils";

interface ITextWithSwatchesProps {
  text: string;
}

const TextWithSwatches: React.FC<ITextWithSwatchesProps> = ({ text }) => {
  const hexCodes = detectHexCodes(text);

  if (!hexCodes.length) return <span>{text}</span>;

  const parts = text.split(/(#(?:[0-9a-fA-F]{3}){1,2}\b)/g);

  return (
    <span>
      {parts.map((part, index) =>
        hexCodes.includes(part) ? (
          <ColorSwatch key={index} hexCode={part} />
        ) : (
          <span key={index}>{part}</span>
        ),
      )}
    </span>
  );
};

export default TextWithSwatches;
