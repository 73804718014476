import React, { useEffect, useState } from "react";
import "./tagger.style.css";
import { cn } from "~/src/util/reusables";

interface SelectorProps {
  create?: boolean;
  options: string[];
  current: string[];
  pillStyle?: any;
  mutator?: (e: string) => any;
  onChange: (c: string) => void;
}

const Selector = ({
  create,
  options,
  current,
  pillStyle,
  mutator,
  onChange,
}: SelectorProps) => {
  const [tempChoices, setTempChoices] = useState<Array<string>>([]);
  const [newChoice, setNewChoice] = useState<string>("");

  if (!mutator) mutator = (e: any) => e;

  useEffect(() => {
    if (create) {
      setTempChoices(current.filter((c) => !options?.includes(c)));
    }
  }, [current]);

  return (
    <div className="flex flex-row flex-wrap items-center">
      {create && (
        <input
          type="text"
          className="fl-input preset-input"
          data-testid="select-input"
          placeholder="Add custom option..."
          value={newChoice}
          onChange={(e) => setNewChoice(e.target.value)}
          onKeyUp={(e) => {
            if (e.key === "Enter") {
              if (newChoice.length > 0) {
                setTempChoices([...tempChoices, newChoice]);
                onChange(newChoice);
                setNewChoice("");
              }
            }
          }}
        />
      )}
      {options?.map((e, i) => (
        <div
          className={cn(
            "mb-2 mr-2 cursor-pointer rounded-md border px-3 py-1 text-xs shadow-sm transition-colors",
            current?.includes(e) &&
              "border-white bg-primary text-white shadow-fl",
          )}
          key={`select-option-${i}`}
          data-testid={`select-${options[0][0]}-option-${i}`}
          onClick={() => onChange(e)}
          style={pillStyle}
        >
          {mutator(e)}
        </div>
      ))}
      {create &&
        tempChoices?.map((e, i) => (
          <div
            className="tag blue selected"
            key={`temp-${i}`}
            data-testid={`temp-${i}`}
            onClick={() => onChange(e)}
          >
            {mutator(e)}
          </div>
        ))}
    </div>
  );
};

export default Selector;
