import { useInfiniteQuery } from "@tanstack/react-query";
import API, { dynamicVariables, variableToLabel } from "~/src/api/withApi";
import { Smile, Variable, Search, Languages, Hash, AtSign } from "lucide-react";
import React, { useEffect, useState } from "react";
import { useIntersectionObserver } from "usehooks-ts";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../../primitives/popover";
import EmojiPicker from "emoji-picker-react";
import { cn, TooltipButton } from "../../../util/reusables";
import { Button } from "../../../primitives/button";
import { Input } from "../../../primitives/input";
import { Separator } from "../../../primitives/separator";
import { PlatformType } from "../../../util/platforms";
import { useDispatch } from "react-redux";
import { JSONContent } from "@tiptap/core";
import {
  accountToMentionContent,
  variableContent,
  stringToTextContent,
} from "./editors/CaptionEditor";
import { setSuccess } from "../../../reducers/toolkit";
import AccountsSearch from "./AccountsSearch";

type CaptionToolbarProps = {
  onInsert: (content: JSONContent) => void;
  disabled?: Record<"mentions" | "variables" | "translate", string>;
  platform?: PlatformType;
};
export default function CaptionToolbar({
  onInsert,
  disabled,
  platform,
}: CaptionToolbarProps) {
  const dispatch = useDispatch();

  // Emoji State
  const [emojisOpen, setEmojisOpen] = useState<boolean>(false);

  // Hashtags State
  const [hashtagsSearch, setHashtagsSearch] = useState<string>("");
  const { data, fetchNextPage, hasNextPage } = useInfiniteQuery({
    queryKey: ["hashtags", hashtagsSearch],
    queryFn: ({ pageParam }) =>
      API.fetchHashtags({
        search: hashtagsSearch,
        limit: 50,
        pageParam,
      }),
    initialPageParam: 1,
    getNextPageParam: (lastPage) => lastPage.nextCursor,
  });
  const { isIntersecting, ref } = useIntersectionObserver({ threshold: 0.5 });
  useEffect(() => {
    if (isIntersecting && hasNextPage) fetchNextPage();
  }, [isIntersecting]);

  return (
    <div className="flex items-center gap-1 rounded-lg border">
      <Popover open={emojisOpen} onOpenChange={setEmojisOpen}>
        <TooltipButton text="Emojis">
          <PopoverTrigger asChild>
            <Button
              variant="ghost"
              size="icon-sm"
              className="aria-expanded:bg-primary aria-expanded:text-primary-foreground"
            >
              <Smile className="size-4" />
            </Button>
          </PopoverTrigger>
        </TooltipButton>
        <PopoverContent align="start" className="w-auto p-0">
          <EmojiPicker
            height={400}
            lazyLoadEmojis
            autoFocusSearch={false}
            onEmojiClick={(emojiData) => {
              onInsert(stringToTextContent(emojiData.emoji));
              setEmojisOpen(false);
            }}
          />
        </PopoverContent>
      </Popover>
      <Popover>
        <TooltipButton text="Hashtags">
          <PopoverTrigger asChild>
            <Button
              variant="ghost"
              size="icon-sm"
              className="aria-expanded:bg-primary aria-expanded:text-primary-foreground"
            >
              <Hash className="size-4" />
            </Button>
          </PopoverTrigger>
        </TooltipButton>
        <PopoverContent
          side="bottom"
          align="start"
          className="w-fit overflow-hidden rounded-lg border p-0 shadow-sm"
        >
          <div className="relative h-fit w-full bg-muted p-2">
            <Search className="absolute left-3 top-1/2 z-20 ml-2 size-3 -translate-y-1/2 transform text-muted-foreground" />
            <Input
              value={hashtagsSearch}
              onChange={(e) => setHashtagsSearch(e.target.value)}
              placeholder="Search"
              className="h-8 w-full bg-background py-1 pl-8 pr-4"
            />
          </div>
          <Separator />
          <div className="max-h-72 overflow-y-auto">
            {data?.pages?.map((page, i) => (
              <React.Fragment key={i}>
                {page?.hashtags?.map((hashtag) => (
                  <div
                    key={`hashtag-${hashtag._id}`}
                    onClick={() =>
                      onInsert(stringToTextContent(hashtag.hashtag))
                    }
                    className="flex cursor-pointer items-center gap-2 px-3 py-2 text-sm hover:bg-primary/5"
                  >
                    {hashtag.hashtag}
                  </div>
                ))}
              </React.Fragment>
            ))}
            {Boolean(data?.pages?.length) && hasNextPage && (
              <div
                ref={ref}
                className={cn(
                  "flex animate-pulse items-center justify-center gap-2 p-2",
                )}
              >
                <div className="size-2 rounded-full bg-gray-500"></div>
                <div className="size-2 rounded-full bg-gray-500"></div>
                <div className="size-2 rounded-full bg-gray-500"></div>
              </div>
            )}
          </div>
        </PopoverContent>
      </Popover>

      <Popover>
        <TooltipButton text={disabled?.["mentions"] ?? "Mention Accounts"}>
          <PopoverTrigger asChild disabled={Boolean(disabled?.["mentions"])}>
            <Button
              variant="ghost"
              size="icon-sm"
              className="aria-expanded:bg-primary aria-expanded:text-primary-foreground"
              disabled={Boolean(disabled?.["mentions"])}
            >
              <AtSign className="size-4" />
            </Button>
          </PopoverTrigger>
        </TooltipButton>
        <PopoverContent
          side="bottom"
          align="start"
          className="w-fit overflow-hidden rounded-lg border p-0 shadow-sm"
        >
          <AccountsSearch
            platform={platform}
            onSelectAccount={(account) => {
              onInsert(accountToMentionContent(account, platform));
              dispatch(
                setSuccess(
                  `Mentioned ${
                    account.platformAccountName ??
                    account.platformAccountUsername
                  }`,
                ),
              );
            }}
          />
        </PopoverContent>
      </Popover>

      <Popover>
        <TooltipButton text={disabled?.["variables"] ?? "Location Variables"}>
          <PopoverTrigger asChild disabled={Boolean(disabled?.["variables"])}>
            <Button
              variant="ghost"
              size="icon-sm"
              className="aria-expanded:bg-primary aria-expanded:text-primary-foreground"
              disabled={Boolean(disabled?.["variables"])}
            >
              <Variable className="size-4" />
            </Button>
          </PopoverTrigger>
        </TooltipButton>
        <PopoverContent side="bottom" align="start" className="w-auto p-0">
          <div className="w-fit rounded-lg border shadow-sm">
            {dynamicVariables.map((variable) => (
              <div
                key={variable}
                className="flex cursor-pointer items-center gap-2 px-3 py-2 text-sm hover:bg-primary/5"
                onClick={() => {
                  onInsert(variableContent(variable));
                }}
              >
                <Variable className="size-3" /> {variableToLabel(variable)}
              </div>
            ))}
          </div>
        </PopoverContent>
      </Popover>

      <Popover>
        <TooltipButton text={disabled?.["translate"] ?? "Translate"}>
          <PopoverTrigger asChild disabled={Boolean(disabled?.["translate"])}>
            <Button
              variant="ghost"
              size="icon-sm"
              className="aria-expanded:bg-primary aria-expanded:text-primary-foreground"
              disabled={Boolean(disabled?.["translate"])}
            >
              <Languages className="size-4" />
            </Button>
          </PopoverTrigger>
        </TooltipButton>
        <PopoverContent side="bottom" align="start" className="w-auto p-0">
          <div className="w-fit rounded-lg border shadow-sm">
            TRANSLATIONS...
          </div>
        </PopoverContent>
      </Popover>
    </div>
  );
}
