import React from "react";
import { PlatformType } from "../../../../util/platforms";
import { cn, NewSocialToLogo } from "../../../../util/reusables";

interface PfpWithPlatformProps {
  platform: PlatformType;
  platformAccountId: string;
  platformAccountName?: string;
  platformAccountPfp?: string;
  className?: string;
}

export const PfpWithPlatform: React.FC<PfpWithPlatformProps> = ({
  platform,
  // platformAccountId,
  platformAccountName,
  platformAccountPfp,
  className,
}) => {
  const fallbackInitial = platformAccountName?.[0]?.toUpperCase() ?? "🔥";

  return (
    <div className="relative">
      <div
        className={cn(
          "flex items-center justify-center overflow-hidden rounded-full bg-muted",
          className,
        )}
      >
        {platformAccountPfp ? (
          <img
            src={platformAccountPfp}
            alt={platformAccountName || "Profile picture"}
            className="h-full w-full object-cover"
            crossOrigin="anonymous"
            onError={(e) => {
              e.currentTarget.style.display = "none";
              const parentElement = e.currentTarget.parentElement;
              if (parentElement) {
                parentElement.setAttribute("data-fallback", "true");
              }
            }}
          />
        ) : (
          <span className="text-xs font-medium">{fallbackInitial}</span>
        )}
      </div>
      <div className="absolute -bottom-1 -right-1">
        <NewSocialToLogo platform={platform} className="h-4 w-4" />
      </div>
    </div>
  );
};
