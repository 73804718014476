import React, { useContext, useState } from "react";
import API, { UserAccount } from "~/src/api/withApi";
import { useDispatch } from "react-redux";
import { ApprovalWorkflow, OrganizationType } from "~/src/api/organization.api";
import FadeIn from "~/src/partials/Transitions/FadeIn";
import { Button } from "~/src/primitives/button";
import { Card } from "~/src/primitives/card";
import { setUser } from "~/src/reducers/user";
import {
  ArrowRight,
  Check,
  Cog,
  MoreHorizontal,
  Trash,
  UserCheck,
} from "lucide-react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "~/src/primitives/table";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "~/src/primitives/dropdown-menu";
import { OrganizationContext } from "../Organization";
import CreateApprovalWorkflowModal from "./components/CreateApprovalWorkflowModal";
import UpdateApprovalWorkflowModal from "./components/UpdateApprovalWorkflowModal";
import DeleteApprovalWorkflowModal from "./components/DeleteApprovalWorkflowModal";
import { TooltipButton, username, withPfp } from "~/src/util/reusables";

export const approvalWorkflowFromId = (
  awfId: string,
  organization: Partial<OrganizationType>,
) => organization?.approvalWorkflows?.find((awf) => awf._id === awfId);

const Approval = () => {
  const dispatch = useDispatch();
  const organization = useContext(OrganizationContext);
  const [showCreateApprovalWorkflowModal, setShowCreateApprovalWorkflowModal] =
    useState<boolean>();
  const [showUpdateApprovalWorkflowModal, setShowUpdateApprovalWorkflowModal] =
    useState<boolean>();
  const [showDeleteApprovalWorkflowModal, setShowDeleteApprovalWorkflowModal] =
    useState<boolean>();
  const [selectedApprovalWorkflow, setSelectedApprovalWorkflow] =
    useState<ApprovalWorkflow>();

  const approvalWorkflows =
    organization?.approvalWorkflows as ApprovalWorkflow[];

  const onCreateApprovalWorkflow = (name: string, approvers: string[]) =>
    API.createApprovalWorkflow(name, approvers)
      .then(() => API.getSession("?update=true"))
      .then(({ user }) => dispatch(setUser(user)));

  const onUpdateApprovalWorkflow = (
    approvalWorkflowId: string,
    diff: Partial<ApprovalWorkflow>,
  ) =>
    API.updateApprovalWorkflow(approvalWorkflowId, diff)
      .then(() => API.getSession("?update=true"))
      .then(({ user }) => dispatch(setUser(user)));

  const onDeleteApprovalWorkflow = (approvalWorkflowId: string) =>
    API.deleteApprovalWorkflow(approvalWorkflowId)
      .then(() => API.getSession("?update=true"))
      .then(({ user }) => dispatch(setUser(user)));

  return (
    <FadeIn show timeout={100}>
      <Card className="mt-2 w-full p-4">
        <div className="mb-4 flex flex-row items-center justify-between">
          <div className="flex flex-row justify-start space-x-2">
            <Button
              variant="default"
              size="sm"
              onClick={() => setShowCreateApprovalWorkflowModal(true)}
            >
              <UserCheck className="mr-2 h-4 w-4" /> Create Approval Workflow
            </Button>
          </div>
        </div>

        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>Name</TableHead>
              <TableHead>Workflow</TableHead>
              <TableHead></TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {approvalWorkflows?.map((awf) => (
              <TableRow key={`seat-user-${awf._id}`}>
                <TableCell className="flex flex-row items-center space-x-4">
                  {awf?.name}
                </TableCell>
                <TableCell>
                  <div className="flex flex-row items-center space-x-2">
                    {(awf.approvers as UserAccount[])?.map((user) => (
                      <div
                        className="flex shrink-0 flex-row items-center"
                        key={user._id}
                      >
                        <TooltipButton text={username(user)}>
                          <img
                            src={withPfp(user?.pfp, username(user))}
                            crossOrigin="anonymous"
                            className="h-8 w-8 rounded-full border border-white shadow-fl"
                          />
                        </TooltipButton>

                        <ArrowRight className="ml-2 h-4 w-4" />
                      </div>
                    ))}
                    <TooltipButton text="Ready To Post">
                      <div className="relative mr-2 flex h-8 w-8 flex-row items-center justify-center rounded-full border shadow-fl">
                        <Check className="h-4 w-4 text-green-400" />
                      </div>
                    </TooltipButton>
                  </div>
                </TableCell>
                <TableCell>
                  <div className="flex h-full flex-row items-center">
                    <DropdownMenu>
                      <DropdownMenuTrigger>
                        <MoreHorizontal className="h-4 w-4" />
                      </DropdownMenuTrigger>
                      <DropdownMenuContent>
                        <DropdownMenuGroup>
                          <DropdownMenuItem
                            onClick={() => {
                              setSelectedApprovalWorkflow(awf);
                              setShowUpdateApprovalWorkflowModal(true);
                            }}
                          >
                            <Cog className="mr-2 h-4 w-4" /> Modify Workflow
                          </DropdownMenuItem>
                          <DropdownMenuItem
                            onClick={() => {
                              setSelectedApprovalWorkflow(awf);
                              setShowDeleteApprovalWorkflowModal(true);
                            }}
                          >
                            <Trash className="mr-2 h-4 w-4" /> Delete Workflow
                          </DropdownMenuItem>
                        </DropdownMenuGroup>
                      </DropdownMenuContent>
                    </DropdownMenu>
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Card>

      <CreateApprovalWorkflowModal
        show={showCreateApprovalWorkflowModal}
        onHide={() => setShowCreateApprovalWorkflowModal(false)}
        onCreate={onCreateApprovalWorkflow}
      />

      <UpdateApprovalWorkflowModal
        show={showUpdateApprovalWorkflowModal}
        approvalWorkflow={selectedApprovalWorkflow}
        onHide={() => setShowUpdateApprovalWorkflowModal(false)}
        onUpdate={onUpdateApprovalWorkflow}
      />

      <DeleteApprovalWorkflowModal
        show={showDeleteApprovalWorkflowModal}
        onHide={() => setShowDeleteApprovalWorkflowModal(false)}
        onDelete={() => onDeleteApprovalWorkflow(selectedApprovalWorkflow._id)}
      />
    </FadeIn>
  );
};

export default Approval;
