import axios from "axios";
import { PlatformType } from "../util/platforms";
import { LocationAccountInfo } from "./locations.api";
import { LarvalPost, Post } from "./portfolio.api";

const getScheduledPosts = (q?: string) => fetch(`/api/calendar${q ?? ""}`);

const getScheduledPost = async (larvalId: string) =>
  (await axios.get(`/api/calendar/${larvalId}`)).data;

const getLivePosts = (params: { from: number; to: number }) =>
  fetch(
    `/api/calendar/posts?from=${params.from.toString()}&to=${params.to.toString()}`,
  );
const getLivePost = (postId: string): Promise<Post> =>
  fetch(`/api/calendar/posts/${postId}`).then((res) => res.json());

const createScheduledPost = (schedule: CreateScheduledPost) =>
  fetch(`/api/calendar/`, {
    method: "POST",
    body: JSON.stringify(schedule),
    headers: {
      "Content-Type": "application/json",
    },
  }).then((res) => res.json());

const createManyScheduledPosts = (schedule: CreateManyScheduledPosts) =>
  fetch(`/api/calendar/posts`, {
    method: "POST",
    body: JSON.stringify(schedule),
    headers: {
      "Content-Type": "application/json",
    },
  }).then((res) => res.json());

export const updateScheduledPost = async (
  larvalPostId: string,
  diff: Partial<LarvalPost>,
  isReady?: boolean,
) => {
  const response = await axios.patch(`/api/calendar/${larvalPostId}`, {
    ...diff,
    isDraft: !isReady,
  });
  return response.data;
};

const updateManyScheduledPosts = (
  larvalPostIds: string[],
  diff: Partial<LarvalPost> & { isDraft: boolean },
  isCampaign = false,
) => {
  const url = new URL("/api/calendar/bulk", window.location.origin);
  url.searchParams.set("isCampaign", String(isCampaign));
  for (const larvalId of larvalPostIds) {
    url.searchParams.append("larvalId", larvalId);
  }
  fetch(url, {
    method: "PATCH",
    body: JSON.stringify(diff),
    headers: {
      "Content-Type": "application/json",
    },
  });
};

const deleteScheduledPost = (larvalPostId: string) =>
  fetch(`/api/calendar/${larvalPostId}`, {
    method: "DELETE",
  });

const getAwaitingApproval = async () =>
  (await axios.get(`/api/calendar/approve`))?.data;

const approveLarvalPost = (larvalId: string) =>
  fetch(`/api/calendar/approve/${larvalId}`, {
    method: "POST",
  });
const adminApproveLarvalPost = (larvalId: string) =>
  fetch(`/api/calendar/approve/${larvalId}/admin`, {
    method: "POST",
  });

const rejectLarvalPost = (larvalId: string) =>
  fetch(`/api/calendar/approve/${larvalId}`, {
    method: "DELETE",
  });

const createCommentOnLarvalPost = (larvalId: string, comment: string) =>
  fetch(`/api/calendar/${larvalId}/comment`, {
    method: "POST",
    body: JSON.stringify({
      comment,
    }),
    headers: {
      "Content-Type": "application/json",
    },
  });

const deleteCommentOnLarvalPost = (larvalId: string, commentid: string) =>
  fetch(`/api/calendar/${larvalId}/comment/${commentid}`, {
    method: "DELETE",
  });

type CreateScheduledPost = {
  portfolioItemId: string;
  timestamp: number;
  platform: PlatformType;
  platformContentType: string;
  isDraft: boolean;
  broadcastParentHub?: string;
};

type CreateManyScheduledPosts = Omit<
  CreateScheduledPost,
  "platform" | "platformContentType"
> & { accounts: LocationAccountInfo[] };

export interface CalendarAPI {
  getScheduledPosts: (q?: string) => Promise<Response>;
  getScheduledPost: (larvalId: string) => Promise<LarvalPost>;
  getLivePosts: (params: { from: number; to: number }) => Promise<Response>;
  getLivePost: (postId: string) => Promise<Post>;
  createScheduledPost: (schedule: CreateScheduledPost) => Promise<LarvalPost>;
  createManyScheduledPosts: (
    schedule: CreateManyScheduledPosts,
  ) => Promise<LarvalPost[]>;
  updateManyScheduledPosts: (
    larvalPostIds: string[],
    diff: Partial<LarvalPost> & { isDraft: boolean },
    isCampaign?: boolean,
  ) => Promise<Response>;
  deleteScheduledPost: (larvalPostId: string) => Promise<Response>;
  getAwaitingApproval: () => Promise<LarvalPost[]>;
  approveLarvalPost: (larvalId: string) => Promise<Response>;
  adminApproveLarvalPost: (larvalId: string) => Promise<Response>;
  rejectLarvalPost: (larvalId: string) => Promise<Response>;
  createCommentOnLarvalPost: (
    larvalId: string,
    comment: string,
  ) => Promise<Response>;
  deleteCommentOnLarvalPost: (
    larvalId: string,
    commentid: string,
  ) => Promise<Response>;
}

export default {
  getScheduledPosts,
  getScheduledPost,
  getLivePosts,
  getLivePost,
  createScheduledPost,
  createManyScheduledPosts,
  updateManyScheduledPosts,
  deleteScheduledPost,
  getAwaitingApproval,
  approveLarvalPost,
  adminApproveLarvalPost,
  rejectLarvalPost,
  createCommentOnLarvalPost,
  deleteCommentOnLarvalPost,
};
