import { UseMutateFunction } from "@tanstack/react-query";
import {
  ChevronDown,
  ChevronRight,
  ClipboardCopy,
  Mail,
  MailOpen,
  MoreHorizontal,
  Trash2,
} from "lucide-react";
import React, { Dispatch, SetStateAction, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IPlatformAccountTag, IPlatformComment } from "~/src/api/engage.api";
import { Button } from "~/src/primitives/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "~/src/primitives/dropdown-menu";
import { setSuccess } from "~/src/reducers/toolkit";
import dayjs from "~/src/util/dayjs";
import { getPlatformName, PlatformType } from "~/src/util/platforms";
import { cn, NewSocialToLogo, pluralize, withPfp } from "~/src/util/reusables";
import { RootState } from "../../store";
import ViewOnlyCaptionWithFallback from "../Post/components/editors/ViewOnlyCaptionWithFallback";
import { getActiveAccountForPlatform } from "./lib/utils";

export type NestedComment = IPlatformComment & {
  children?: IPlatformComment[];
};
type CommentProps = {
  comment: NestedComment;
  setReplyToComment: Dispatch<SetStateAction<NestedComment>>;
  replyToComment: NestedComment;
  accounts: IPlatformAccountTag[];
  deleteComment: UseMutateFunction<
    any,
    Error,
    {
      platform: PlatformType;
      platformPostId: string;
      platformCommentId: string;
    },
    {
      payload: string;
      type: "toolkit/setLoading";
    }
  >;
  markAsUnreadComment: UseMutateFunction<Response, Error, string, unknown>;
  markAsReadComment: UseMutateFunction<Response, Error, string, unknown>;
  focusedPost?: any;
};
export default function Comment({
  comment,
  setReplyToComment,
  replyToComment,
  accounts,
  deleteComment,
  markAsUnreadComment,
  markAsReadComment,
  focusedPost,
}: CommentProps) {
  const {
    _id,
    platform,
    // platformAccountId,
    platformPostId,
    platformCommentId,
    // platformCommentParentId,
    content,
    timestamp,
    fromPlatformAccountId,
    fromPlatformAccountName,
    likesCount,
    childCommentsCount,
    // hidden,
    children,
    markedRead,
  } = comment;
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.user);
  const { key } = getActiveAccountForPlatform(
    user.workspace,
    PlatformType.Facebook,
  );
  const { name } = getActiveAccountForPlatform(
    user.workspace,
    PlatformType.Instagram,
  );

  const isInbound =
    platform === PlatformType.Facebook
      ? platformPostId?.split("_")?.[0] !== fromPlatformAccountId
      : focusedPost?.isTaggedPost && platform === PlatformType.Instagram
        ? fromPlatformAccountName !== name
        : fromPlatformAccountId !== focusedPost?.platformAccountId;

  const [isExpanded, setIsExpanded] = useState(true);

  const commentDate = dayjs(timestamp);

  const fromAccount = accounts?.find(
    (acc) =>
      acc.platformAccountId === fromPlatformAccountId ||
      (acc.platformAccountUsername &&
        acc.platformAccountUsername === fromPlatformAccountName),
  );

  return (
    <div
      className={cn(
        "flex h-auto w-full gap-4",
        !isInbound && "flex-row-reverse",
        // hidden && "opacity-50",
      )}
    >
      {/* PFP */}
      <div className="h-10 w-10 rounded-full drop-shadow">
        <img
          src={withPfp(
            fromAccount?.platformAccountPfp ||
              (platform === PlatformType.Facebook
                ? `https://graph.facebook.com/v17.0/${fromPlatformAccountId}/picture?access_token=${key}`
                : ""),
            fromPlatformAccountId,
          )}
          onError={(event) =>
            ((event.target as any).src = withPfp(
              "",
              fromAccount?.platformAccountId,
            ))
          }
          className="rounded-full object-cover"
          crossOrigin="anonymous"
        />
      </div>
      {/* Content */}
      <div className="flex w-full flex-col gap-2">
        <div
          className={cn(
            "flex w-full",
            isInbound ? "jusify-start" : "justify-end",
          )}
          key={timestamp}
        >
          <div className="flex w-auto min-w-96 flex-col gap-1">
            <div
              className={cn(
                "grid max-w-md gap-1 rounded-lg bg-[#E9E9EB] p-3 shadow-fl",
                isInbound ? "mr-4" : "ml-4",
              )}
            >
              <div className="flex w-full justify-between">
                <div className="flex items-center gap-2 text-xs tracking-wide opacity-80">
                  {fromPlatformAccountName ?? "Facebook User"}{" "}
                  <span className="text-2xs text-gray-500">
                    {commentDate.fromNow()}
                  </span>
                </div>{" "}
                <DropdownMenu>
                  <DropdownMenuTrigger asChild>
                    <Button variant="ghost" size="xs" className="rounded">
                      <span className="sr-only">Actions</span>
                      <MoreHorizontal className="h-4 w-4" />
                    </Button>
                  </DropdownMenuTrigger>
                  <DropdownMenuContent align="end">
                    {markedRead ? (
                      <DropdownMenuItem
                        onSelect={() => markAsUnreadComment(_id)}
                        className="text-xs"
                      >
                        <Mail className="mr-2 h-4 w-4" /> Mark as unread
                      </DropdownMenuItem>
                    ) : (
                      <DropdownMenuItem
                        onSelect={() => markAsReadComment(_id)}
                        className="text-xs"
                      >
                        <MailOpen className="mr-2 h-4 w-4" /> Mark as read
                      </DropdownMenuItem>
                    )}
                    <DropdownMenuItem
                      onSelect={() =>
                        window.open(
                          focusedPost?.permalink ?? focusedPost?.permalink_url,
                          "_blank",
                          "noopener,noreferrer",
                        )
                      }
                      className="flex items-center gap-1 text-xs text-gray-600"
                    >
                      <NewSocialToLogo platform={platform} />
                      View on {getPlatformName(platform)}
                    </DropdownMenuItem>
                    <DropdownMenuItem
                      onSelect={() =>
                        deleteComment({
                          platform,
                          platformPostId,
                          platformCommentId,
                        })
                      }
                      className="text-xs text-red-600"
                    >
                      <Trash2 className="mr-2 h-4 w-4" /> Delete comment
                    </DropdownMenuItem>
                    <DropdownMenuSeparator />
                    <DropdownMenuItem
                      onSelect={() => {
                        navigator.clipboard.writeText(platformCommentId);
                        dispatch(setSuccess("Comment ID copied to clipboard!"));
                      }}
                      className="text-xs"
                    >
                      <ClipboardCopy className="mr-2 h-3 w-3" /> Copy comment ID
                    </DropdownMenuItem>
                  </DropdownMenuContent>
                </DropdownMenu>
              </div>

              <ViewOnlyCaptionWithFallback
                className="font break-words text-sm"
                stringContent={content}
              />
              {/* {!!media?.uri && (
                <NewLoadMedia
                  src={media.uri}
                  mimeType={media.mimeType}
                  containerClassName="max-w-[125px] cursor-zoom-in"
                  onClick={() => setDisplayMedia(media)}
                />
              )} */}
            </div>
            <div
              className={cn(
                "flex max-w-md justify-between opacity-60",
                isInbound ? "mr-4" : "ml-4",
              )}
            >
              <div>
                <div className="px-2 text-xs">
                  {pluralize(likesCount, "like")}
                </div>
                {!!comment.children && (
                  <Button
                    onClick={() => setIsExpanded(!isExpanded)}
                    size="xs"
                    variant="ghost"
                    className="px-2 font-semibold text-[#1455BC]"
                  >
                    {pluralize(childCommentsCount, "comment")}
                    {isExpanded ? (
                      <ChevronDown className="ml-1 h-3 w-3" />
                    ) : (
                      <ChevronRight className="ml-1 h-3 w-3" />
                    )}
                  </Button>
                )}
              </div>
              <div className="flex gap-2">
                {/* Seeing issue: Publishing likes through the API is only available for page access tokens */}
                {/* <Button
                  size="xs"
                  variant="ghost"
                  className="h-fit px-2"
                  // onClick={async () => await createLike(id)}
                >
                  Like
                </Button> */}
                <Button
                  size="xs"
                  variant="ghost"
                  className="h-fit px-2"
                  onClick={() => setReplyToComment(comment)}
                >
                  Reply
                </Button>
              </div>
            </div>
            {isExpanded && (
              <div className="flex h-full w-full flex-col gap-4">
                {children?.map((c) => (
                  <Comment
                    comment={c}
                    key={c.platformCommentId}
                    setReplyToComment={setReplyToComment}
                    replyToComment={replyToComment}
                    accounts={accounts}
                    deleteComment={deleteComment}
                    focusedPost={focusedPost}
                    markAsUnreadComment={markAsUnreadComment}
                    markAsReadComment={markAsReadComment}
                    // setDisplayMedia={setDisplayMedia}
                    // toggleCommentVisibility={toggleCommentVisibility}
                    // createLike={createLike}
                  />
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
