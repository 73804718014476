import React, { useMemo, useState } from "react";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { Badge } from "~/src/primitives/badge";
import { Skeleton } from "~/src/primitives/skeleton";
import {
  CalendarIcon,
  BadgeCheck,
  Layers3,
  FlagIcon,
  Loader2,
  RefreshCw,
} from "lucide-react";
import { Button } from "~/src/primitives/button";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "~/src/primitives/accordion";
import { useParams } from "react-router-dom";
import { getPlatformName } from "../../util/platforms";
import { Preview } from "../../lib/Preview/Preview";
import dayjs from "../../util/dayjs";
import {
  getPortfolioItemWithBrandChecks,
  approveBrandCheck,
  rejectBrandCheck,
  addCommentToBrandCheck,
  createBrandCheck,
} from "../../api/brandcheck.api";
import { Textarea } from "~/src/primitives/textarea";
import { toast } from "~/src/primitives/use-toast";
import { getPostProperties } from "../Post/utils";
import {
  Select,
  SelectItem,
  SelectGroup,
  SelectContent,
  SelectValue,
  SelectTrigger,
} from "../../primitives/select";
import Tinybadge from "../../primitives/tinybadge";
import { PfpWithPlatform } from "../Post/components/shared/PfpWithPlatform";
import { capitalize } from "lodash";
import { LocationAccountInfo } from "../../api/locations.api";
import LoadMedia from "../../partials/Load/LoadMedia";
import TextWithSwatches from "../../partials/ColorSwatch/TextWithSwatches";

export const BrandCheck = () => {
  // const navigate = useNavigate();
  const { portfolioItemId } = useParams();
  const queryClient = useQueryClient();
  const [comment, setComment] = useState("");
  const [approvalNotes, setApprovalNotes] = useState("");
  const [focusedAccount, setFocusedAccount] =
    useState<LocationAccountInfo | null>(null);
  const [selectedBrandCheckIndex, setSelectedBrandCheckIndex] = useState(0);

  const { data, isLoading, error } = useQuery({
    queryKey: ["portfolioItemWithBrandChecks", portfolioItemId],
    queryFn: () =>
      getPortfolioItemWithBrandChecks(portfolioItemId).then((res) => {
        setFocusedAccount(res.accounts?.[0]);
        return res;
      }),
    enabled: !!portfolioItemId,
  });

  const accounts = data?.accounts ?? [];
  const platforms = useMemo(
    () =>
      Array.from(
        new Set(accounts?.map((a) => getPlatformName(a.platform)) || []),
      ),
    [accounts],
  );

  const generateBrandCheckMutation = useMutation({
    mutationFn: createBrandCheck,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["portfolioItemWithBrandChecks", portfolioItemId],
      });
      toast({
        title: "Brand check created",
        description: "A new brand check has been created successfully.",
      });
    },
    onError: (error) => {
      toast({
        title: "Error",
        description: "Failed to create brand check.",
        variant: "destructive",
      });
      console.error("Brand check creation error:", error);
    },
  });

  const approveMutation = useMutation({
    mutationFn: ({
      brandCheckId,
      notes,
    }: {
      brandCheckId: string;
      notes?: string;
    }) => approveBrandCheck(brandCheckId, notes),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["portfolioItemWithBrandChecks", portfolioItemId],
      });
      toast({
        title: "Brand check approved",
        description: "The brand check has been approved successfully.",
      });
      setApprovalNotes("");
    },
    onError: () => {
      toast({
        title: "Error",
        description: "Failed to approve brand check.",
        variant: "destructive",
      });
    },
  });

  const rejectMutation = useMutation({
    mutationFn: ({
      brandCheckId,
      notes,
    }: {
      brandCheckId: string;
      notes?: string;
    }) => rejectBrandCheck(brandCheckId, notes),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["portfolioItemWithBrandChecks", portfolioItemId],
      });
      toast({
        title: "Brand check rejected",
        description: "The brand check has been rejected successfully.",
      });
      setApprovalNotes("");
    },
    onError: () => {
      toast({
        title: "Error",
        description: "Failed to reject brand check.",
        variant: "destructive",
      });
    },
  });

  const commentMutation = useMutation({
    mutationFn: ({
      brandCheckId,
      comment,
    }: {
      brandCheckId: string;
      comment: string;
    }) => addCommentToBrandCheck(brandCheckId, comment),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["portfolioItemWithBrandChecks", portfolioItemId],
      });
      toast({
        title: "Comment added",
        description: "Your comment has been added successfully.",
      });
      setComment("");
    },
    onError: () => {
      toast({
        title: "Error",
        description: "Failed to add comment.",
        variant: "destructive",
      });
    },
  });

  if (isLoading || generateBrandCheckMutation.isPending) {
    return <BrandCheckSkeleton />;
  }

  if (error) {
    return <div>Error loading brand check: {error.message}</div>;
  }

  if (!data) {
    return <div>No post data available</div>;
  }

  const hasBrandChecks = data.brandChecks && data.brandChecks.length > 0;
  const sortedBrandChecks = hasBrandChecks
    ? [...data.brandChecks].sort(
        (a, b) =>
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
      )
    : [];

  const latestBrandCheck = hasBrandChecks
    ? sortedBrandChecks[selectedBrandCheckIndex]
    : null;

  const handleApprove = () => {
    if (!latestBrandCheck) return;
    approveMutation.mutate({
      brandCheckId: latestBrandCheck._id,
      notes: approvalNotes || undefined,
    });
  };

  const handleReject = () => {
    if (!latestBrandCheck) return;
    rejectMutation.mutate({
      brandCheckId: latestBrandCheck._id,
      notes: approvalNotes || undefined,
    });
  };

  const handleAddComment = () => {
    if (!latestBrandCheck || !comment.trim()) return;
    commentMutation.mutate({
      brandCheckId: latestBrandCheck._id,
      comment,
    });
  };

  return (
    <div className="flex h-full flex-col gap-6 overflow-y-auto bg-muted p-6">
      <div className="flex items-center justify-between">
        <div className="flex flex-col gap-1">
          <div className="flex items-center gap-4">
            <h1 className="text-3xl font-semibold">Brand Check</h1>
            {hasBrandChecks && sortedBrandChecks.length > 1 && (
              <Select
                value={selectedBrandCheckIndex.toString()}
                onValueChange={(value) =>
                  setSelectedBrandCheckIndex(parseInt(value))
                }
              >
                <SelectTrigger className="h-8 w-auto whitespace-nowrap bg-background text-xs">
                  <SelectValue placeholder="Select version" />
                </SelectTrigger>
                <SelectContent>
                  {sortedBrandChecks.map((brandCheck, index) => (
                    <SelectItem
                      key={brandCheck._id}
                      value={index.toString()}
                      className="text-xs"
                    >
                      Version {sortedBrandChecks.length - index} -{" "}
                      {dayjs(brandCheck.createdAt).fromNow()}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            )}
          </div>
          <p className="text-sm font-normal text-muted-foreground">
            Post by {data.team.name} on {platforms.join(", ")}
          </p>
        </div>

        {/* TODO: Clean up this issue to make this button work better https://gitlab.com/flamel-ai/flamel-ai/-/issues/1238 */}
        {/* <Button variant="secondary" className="gap-2" onClick={() => navigate(`/drafts/${portfolioItemId}`)}>
          <Pencil className="h-4 w-4" />
          Edit Post
        </Button> */}
        {hasBrandChecks && (
          <Button
            variant="ghost"
            size="sm"
            className="gap-2"
            onClick={() => generateBrandCheckMutation.mutate(portfolioItemId)}
            disabled={generateBrandCheckMutation.isPending}
          >
            {generateBrandCheckMutation.isPending ? (
              <>
                <Loader2 className="h-4 w-4 animate-spin" />
                Regenerating...
              </>
            ) : (
              <>
                <RefreshCw className="h-4 w-4" />
                Regenerate Brand Check
              </>
            )}
          </Button>
        )}
      </div>

      <div className="flex gap-6">
        {/* Left column - Post Previews */}
        <div className="flex w-[300px] flex-col gap-2">
          <h3 className="text-lg font-semibold text-muted-foreground">
            Post Previews
          </h3>
          <Select
            value={
              focusedAccount
                ? `${focusedAccount.platform}_${focusedAccount.platformAccountId}`
                : ""
            }
            onValueChange={(val) => {
              if (val) {
                const [platform, platformAccountId] = val.split("_");
                const account =
                  accounts?.find(
                    (sa) =>
                      sa.platformAccountId === platformAccountId &&
                      sa.platform === platform,
                  ) ?? ({} as LocationAccountInfo);
                setFocusedAccount(account);
              }
            }}
          >
            <SelectTrigger
              className="h-auto min-h-12 bg-background py-1 text-xs font-semibold"
              disabled={!accounts?.length}
            >
              <SelectValue
                placeholder="Select an account to preview..."
                className="text-xs font-semibold"
              />
            </SelectTrigger>
            <SelectContent className="max-h-72 overflow-y-auto">
              <SelectGroup>
                {accounts?.map((sa) => (
                  <SelectItem
                    key={`${sa?.platform}_${sa?.platformAccountId}`}
                    value={`${sa.platform}_${sa.platformAccountId}`}
                  >
                    <div className="flex items-center gap-2 py-2">
                      <PfpWithPlatform
                        className="h-6 w-6"
                        platform={sa?.platform}
                        platformAccountId={sa?.platformAccountId}
                        platformAccountName={sa?.platformAccountName}
                        platformAccountPfp={sa?.platformAccountPfp}
                      />
                      <span className="text-xs font-bold">
                        {sa?.platformAccountName}
                      </span>
                      <Tinybadge
                        text={capitalize(
                          getPostProperties(data as any, sa.platform)
                            ?.platformContentType,
                        )}
                      />
                    </div>
                  </SelectItem>
                ))}
              </SelectGroup>
            </SelectContent>
          </Select>
          <div className="rounded-lg">
            <Preview
              item={data as any}
              isWorkingItemLarval={false}
              focusedAccount={focusedAccount}
              carouselIndex={0}
              setCarouselIndex={() => {}}
            />
          </div>
        </div>

        {/* Right container - Details, Feedback, Flagged Issues, and Franchisee Analysis */}
        <div className="flex-1">
          {hasBrandChecks ? (
            <div className="flex flex-col gap-6">
              <div className="grid grid-cols-2 gap-4">
                <div className="flex flex-col gap-6">
                  <div className="flex flex-col gap-2">
                    <h3 className="text-lg font-semibold text-muted-foreground">
                      Details
                    </h3>
                    <div className="rounded-lg bg-background p-4">
                      <div className="flex flex-col gap-4">
                        <div className="flex flex-col gap-1">
                          <div className="flex items-center gap-2">
                            <Layers3 className="h-5 w-5 text-muted-foreground" />
                            <span className="text-sm text-muted-foreground">
                              Workspace
                            </span>
                          </div>
                          <p className="font-medium">{data.team.name}</p>
                        </div>

                        <div className="flex flex-col gap-1">
                          <div className="flex items-center gap-2">
                            <CalendarIcon className="h-5 w-5 text-muted-foreground" />
                            <span className="text-sm text-muted-foreground">
                              Date Created
                            </span>
                          </div>
                          <p className="font-medium">
                            {dayjs(data.createdAt).format(
                              "MMMM D, YYYY [at] h:mm A",
                            )}
                          </p>
                        </div>

                        <div className="flex flex-col gap-1">
                          <div className="flex items-center gap-2">
                            <BadgeCheck className="h-5 w-5 text-muted-foreground" />
                            <span className="text-sm text-muted-foreground">
                              Socials
                            </span>
                          </div>
                          <p className="font-medium">{platforms.join(", ")}</p>
                        </div>

                        {/* TODO: Add support for larvals */}
                        {/* <div className="flex flex-col gap-1">
                        <div className="flex items-center gap-2">
                          <BarChart className="h-5 w-5 text-muted-foreground" />
                          <span className="text-sm text-muted-foreground">Current Metrics</span>
                        </div>
                        <div className="flex items-center gap-4">
                          <div className="flex items-center gap-1">
                            <Eye className="h-4 w-4 text-muted-foreground" />
                            <span className="font-medium">45</span>
                          </div>
                          <div className="flex items-center gap-1">
                            <HeartIcon className="h-4 w-4 text-muted-foreground" />
                            <span className="font-medium">12</span>
                          </div>
                          <div className="flex items-center gap-1">
                            <MessageCircleIcon className="h-4 w-4 text-muted-foreground" />
                            <span className="font-medium">0</span>
                          </div>
                          <div className="flex items-center gap-1">
                            <Share2Icon className="h-4 w-4 text-muted-foreground" />
                            <span className="font-medium">3</span>
                          </div>
                        </div>
                      </div> */}
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col gap-2">
                    <h2 className="text-lg font-semibold text-muted-foreground">
                      Flagged Issues (
                      {latestBrandCheck.flaggedIssues?.length || 0})
                    </h2>
                    <div className="flex flex-col gap-2 rounded-lg bg-background/20 p-4 shadow-fl">
                      {latestBrandCheck.flaggedIssues &&
                      latestBrandCheck.flaggedIssues.length > 0 ? (
                        latestBrandCheck.flaggedIssues.map((issue, index) => (
                          <FlaggedIssue
                            key={index}
                            title={issue.type}
                            description={issue.description}
                          />
                        ))
                      ) : (
                        <p className="text-muted-foreground">
                          No issues flagged
                        </p>
                      )}
                    </div>
                  </div>
                </div>

                <div className="flex flex-col gap-2">
                  <h3 className="text-lg font-semibold text-muted-foreground">
                    Feedback
                  </h3>
                  <div className="rounded-lg bg-background p-4">
                    <div className="flex flex-col gap-4">
                      <div className="flex flex-col gap-1">
                        <div className="flex items-center gap-2">
                          <Badge
                            variant={
                              latestBrandCheck.status === "approved"
                                ? "success"
                                : latestBrandCheck.status === "rejected"
                                  ? "destructive"
                                  : "outline"
                            }
                          >
                            Status:{" "}
                            {latestBrandCheck.status.charAt(0).toUpperCase() +
                              latestBrandCheck.status.slice(1)}
                          </Badge>
                        </div>
                        {latestBrandCheck.status === "approved" &&
                          latestBrandCheck.approvedBy && (
                            <p className="text-sm text-muted-foreground">
                              Approved by{" "}
                              {latestBrandCheck.approvedBy.firstname &&
                              latestBrandCheck.approvedBy.lastname
                                ? `${latestBrandCheck.approvedBy.firstname} ${latestBrandCheck.approvedBy.lastname}`
                                : latestBrandCheck.approvedBy.email}{" "}
                              on{" "}
                              {dayjs(latestBrandCheck.approvalDate).format(
                                "MMMM D, YYYY",
                              )}
                            </p>
                          )}
                        {latestBrandCheck.status === "rejected" &&
                          latestBrandCheck.rejectedBy && (
                            <p className="text-sm text-muted-foreground">
                              Rejected by{" "}
                              {latestBrandCheck.rejectedBy.firstname &&
                              latestBrandCheck.rejectedBy.lastname
                                ? `${latestBrandCheck.rejectedBy.firstname} ${latestBrandCheck.rejectedBy.lastname}`
                                : latestBrandCheck.rejectedBy.email}{" "}
                              on{" "}
                              {dayjs(latestBrandCheck.rejectionDate).format(
                                "MMMM D, YYYY",
                              )}
                            </p>
                          )}
                      </div>

                      {latestBrandCheck.status === "pending" && (
                        <>
                          <Textarea
                            placeholder="Add approval/rejection notes..."
                            value={approvalNotes}
                            onChange={(e) => setApprovalNotes(e.target.value)}
                            className="min-h-[80px]"
                          />
                          <div className="flex gap-2">
                            <Button
                              variant="outline"
                              className="flex-1"
                              onClick={handleReject}
                              disabled={rejectMutation.isPending}
                            >
                              Reject
                            </Button>
                            <Button
                              variant="default"
                              className="flex-1"
                              onClick={handleApprove}
                              disabled={approveMutation.isPending}
                            >
                              Approve
                            </Button>
                          </div>
                        </>
                      )}

                      <div className="mt-4">
                        <h4 className="mb-2 text-sm font-medium">Comments</h4>
                        <div className="mb-2 max-h-[200px] space-y-2 overflow-y-auto">
                          {latestBrandCheck.comments &&
                          latestBrandCheck.comments.length > 0 ? (
                            latestBrandCheck.comments.map((comment) => (
                              <div
                                key={comment._id}
                                className="rounded-md bg-muted p-2"
                              >
                                <div className="flex items-center justify-between">
                                  <span className="text-xs font-semibold">
                                    {comment.user.firstname &&
                                    comment.user.lastname
                                      ? `${comment.user.firstname} ${comment.user.lastname}`
                                      : comment.user.email}
                                  </span>
                                  <span className="text-xs text-muted-foreground">
                                    {dayjs(comment.createdAt).fromNow()}
                                  </span>
                                </div>
                                <p className="mt-1 text-sm">
                                  {<TextWithSwatches text={comment.comment} />}
                                </p>
                              </div>
                            ))
                          ) : (
                            <p className="text-sm text-muted-foreground">
                              No comments yet
                            </p>
                          )}
                        </div>
                        <div className="flex flex-col justify-end gap-2">
                          <Textarea
                            placeholder="Add a comment..."
                            value={comment}
                            onChange={(e) => setComment(e.target.value)}
                            className="min-h-[60px]"
                          />
                          <Button
                            size="sm"
                            className="mt-2"
                            onClick={handleAddComment}
                            disabled={
                              commentMutation.isPending || !comment.trim()
                            }
                          >
                            Add Comment
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Franchisee Analysis moved under the right section */}
              <div className="flex gap-6 pb-8">
                <div className="flex flex-1 flex-col gap-2">
                  <h2 className="text-lg font-semibold text-muted-foreground">
                    Analysis
                  </h2>
                  <Accordion
                    type="single"
                    collapsible
                    className="w-full max-w-2xl rounded-lg bg-background/20 p-4 shadow-fl"
                  >
                    <AccordionItem value="brandcheck">
                      <AccordionTrigger>
                        <div className="flex w-64 items-center justify-between">
                          <span className="font-medium">
                            Brand Check AI Score
                          </span>
                          <span className="font-medium text-accent-blue">
                            {latestBrandCheck.brandCheckScore}/100
                          </span>
                        </div>
                      </AccordionTrigger>
                      <AccordionContent>
                        {
                          <TextWithSwatches
                            text={latestBrandCheck.brandCheckDescription}
                          />
                        }
                        <div className="mt-2">
                          <h4 className="mb-1 text-sm font-medium">
                            Recommendations:
                          </h4>
                          <ul className="list-disc space-y-1 pl-5">
                            {latestBrandCheck.brandCheckRecommendations.map(
                              (rec, idx) => (
                                <li key={idx} className="text-sm">
                                  {<TextWithSwatches text={rec} />}
                                </li>
                              ),
                            )}
                          </ul>
                        </div>
                      </AccordionContent>
                    </AccordionItem>

                    {latestBrandCheck.mediaCheckScore !== undefined && (
                      <AccordionItem value="media">
                        <AccordionTrigger>
                          <div className="flex w-64 items-center justify-between">
                            <span className="font-medium">Media Score</span>
                            <span className="font-medium text-accent-blue">
                              {latestBrandCheck.mediaCheckScore}/100
                            </span>
                          </div>
                        </AccordionTrigger>
                        <AccordionContent>
                          {
                            <TextWithSwatches
                              text={latestBrandCheck.mediaCheckDescription}
                            />
                          }
                          <div className="mt-2">
                            <h4 className="mb-1 text-sm font-medium">
                              Details:
                            </h4>
                            <div className="flex flex-col rounded-lg bg-background text-primary/70">
                              {latestBrandCheck.mediaCheckDetails.map(
                                (detail) => {
                                  const media = data?.media?.find(
                                    (m) => m._id === detail.id,
                                  );
                                  return (
                                    <div
                                      className="flex items-center gap-4 border-b p-2"
                                      key={detail.id}
                                    >
                                      {media && (
                                        <div className="relative w-fit shrink-0">
                                          <LoadMedia
                                            mimeType={media.mimeType}
                                            src={media?.editUri ?? media.uri}
                                            thumbnail={media.thumbnail}
                                            className="pointer-events-none h-auto w-24 object-cover object-center"
                                          />
                                        </div>
                                      )}
                                      <div className="w-16 shrink-0 font-medium text-accent-blue">
                                        {detail.score}/100
                                      </div>
                                      <div className="text-sm">
                                        {
                                          <TextWithSwatches
                                            text={detail.description}
                                          />
                                        }
                                      </div>
                                    </div>
                                  );
                                },
                              )}
                            </div>
                          </div>
                          <div className="mt-2">
                            <h4 className="mb-1 text-sm font-medium">
                              Recommendations:
                            </h4>
                            <ul className="list-disc space-y-1 pl-5">
                              {latestBrandCheck.mediaCheckRecommendations.map(
                                (rec, idx) => (
                                  <li key={idx} className="text-sm">
                                    {<TextWithSwatches text={rec} />}
                                  </li>
                                ),
                              )}
                            </ul>
                          </div>
                        </AccordionContent>
                      </AccordionItem>
                    )}

                    {latestBrandCheck.captionCheckScore !== undefined && (
                      <AccordionItem value="caption">
                        <AccordionTrigger>
                          <div className="flex w-64 items-center justify-between">
                            <span className="font-medium">Caption Score</span>
                            <span className="font-medium text-accent-blue">
                              {latestBrandCheck.captionCheckScore}/100
                            </span>
                          </div>
                        </AccordionTrigger>
                        <AccordionContent>
                          {
                            <TextWithSwatches
                              text={latestBrandCheck.captionCheckDescription}
                            />
                          }
                          <div className="mt-2">
                            <h4 className="mb-1 text-sm font-medium">
                              Recommendations:
                            </h4>
                            <ul className="list-disc space-y-1 pl-5">
                              {latestBrandCheck.captionCheckRecommendations.map(
                                (rec, idx) => (
                                  <li key={idx} className="text-sm">
                                    {<TextWithSwatches text={rec} />}
                                  </li>
                                ),
                              )}
                            </ul>
                          </div>
                        </AccordionContent>
                      </AccordionItem>
                    )}
                  </Accordion>
                </div>

                <div className="flex flex-1 flex-col gap-2">
                  <h2 className="text-lg font-semibold text-muted-foreground">
                    Recommendations
                  </h2>
                  <div className="rounded-lg bg-background/20 p-4 shadow-fl">
                    {latestBrandCheck.brandCheckRecommendations &&
                    latestBrandCheck.brandCheckRecommendations.length > 0 ? (
                      <div className="space-y-4">
                        <p className="text-sm text-muted-foreground">
                          Based on our analysis, here are key recommendations to
                          improve your brand alignment:
                        </p>
                        <div className="flex flex-col gap-2">
                          {latestBrandCheck.brandCheckRecommendations.map(
                            (recommendation, index) => (
                              <div
                                key={index}
                                className="rounded-md border border-border bg-background p-3"
                              >
                                <div className="flex items-start gap-2">
                                  <BadgeCheck className="mt-0.5 h-5 w-5 shrink-0 text-accent-blue" />
                                  <p className="text-sm">
                                    {<TextWithSwatches text={recommendation} />}
                                  </p>
                                </div>
                              </div>
                            ),
                          )}
                        </div>
                        <div className="pt-2">
                          <p className="text-sm text-muted-foreground">
                            Implementing these recommendations can help improve
                            your brand consistency score and overall content
                            performance.
                          </p>
                        </div>
                      </div>
                    ) : (
                      <p className="text-muted-foreground">
                        No recommendations available
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="flex h-full items-center justify-center">
              <div className="max-w-md rounded-lg bg-background p-8 text-center shadow-sm">
                <h3 className="mb-2 text-xl font-semibold">
                  No Brand Check Available
                </h3>
                <p className="mb-4 text-muted-foreground">
                  This post doesn&apos;t have a brand check yet. Generate one to
                  analyze brand alignment.
                </p>
                <Button
                  onClick={() => {
                    generateBrandCheckMutation.mutate(portfolioItemId);
                  }}
                  disabled={generateBrandCheckMutation.isPending}
                >
                  {generateBrandCheckMutation.isPending ? (
                    <>
                      <span className="mr-2 h-4 w-4 animate-spin rounded-full border-2 border-background border-t-foreground"></span>
                      Processing...
                    </>
                  ) : (
                    "Generate Brand Check"
                  )}
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const FlaggedIssue = ({
  title,
  description,
}: {
  title: string;
  description: string;
}) => (
  <div className="rounded-lg border border-[#B91C1B4D] bg-red-50 p-4">
    <div className="flex items-center gap-4">
      <FlagIcon className="size-7 shrink-0 text-[#B91C1B]" />
      <div>
        <h3 className="font-medium text-[#B91C1B]">{title}</h3>
        <p className="text-sm text-[#D43537]">{description}</p>
      </div>
    </div>
  </div>
);

const BrandCheckSkeleton = () => (
  <div className="flex flex-col gap-6 p-4">
    <h1 className="text-3xl font-semibold">Brand Check</h1>
    <div className="grid grid-cols-3 gap-4">
      {[...Array(3)].map((_, i) => (
        <Skeleton key={i} className="h-32" />
      ))}
    </div>
    <div className="grid grid-cols-2 gap-6">
      <div>
        <Skeleton className="mb-4 h-6 w-32" />
        <div className="space-y-4">
          {[...Array(4)].map((_, i) => (
            <Skeleton key={i} className="h-24" />
          ))}
        </div>
      </div>
      <div>
        <Skeleton className="mb-4 h-6 w-32" />
        <div className="space-y-2">
          {[...Array(3)].map((_, i) => (
            <Skeleton key={i} className="h-12" />
          ))}
        </div>
      </div>
    </div>
  </div>
);

export default BrandCheck;
