import { Video } from "lucide-react";
import React from "react";
import { useInView } from "react-intersection-observer";
import { useQuery } from "@tanstack/react-query";
import { videoTypes } from "~/src/util/loaders";
import { cn } from "~/src/util/reusables";
import { isSafari } from "react-device-detect";

// Use interface instead of type for props as per best practices
interface LoadMediaProps {
  alt?: string;
  src: string;
  thumbnail?: string;
  mimeType: string;
  className?: string;
  showVideoIndicator?: boolean;
  hideVideoControls?: boolean;
}

// Extract reusable constants
const FALLBACK_SOURCES = {
  video: "https://flamel-misc.s3.amazonaws.com/video-error-placeholder.mp4",
  image: "https://flamel-misc.s3.amazonaws.com/image-error-placeholder.png",
} as const;

// Enhanced preload function with retry logic
const preloadMedia = (src: string, isVideo: boolean): Promise<string> => {
  const MAX_RETRIES = 3;
  const RETRY_DELAY = 1000;

  const attemptLoad = (retryCount = 0): Promise<string> => {
    return new Promise((resolve, reject) => {
      fetch(src)
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          return response.blob();
        })
        .then((blob) => {
          const blobUrl = URL.createObjectURL(blob);

          if (isVideo) {
            const video = document.createElement("video");

            // Set timeout to prevent hanging
            const timeout = setTimeout(() => {
              URL.revokeObjectURL(blobUrl);
              video.remove();
              if (retryCount < MAX_RETRIES) {
                setTimeout(() => {
                  attemptLoad(retryCount + 1)
                    .then(resolve)
                    .catch(reject);
                }, RETRY_DELAY);
              } else {
                reject(new Error("Video load timeout after retries"));
              }
            }, 10000);

            video.onloadeddata = () => {
              clearTimeout(timeout);
              resolve(blobUrl);
            };

            video.onerror = () => {
              clearTimeout(timeout);
              URL.revokeObjectURL(blobUrl);
              video.remove();

              if (retryCount < MAX_RETRIES) {
                // Try with a different video codec/format if available
                const alternateSource = src.replace(".mp4", ".webm");
                if (alternateSource !== src) {
                  attemptLoad(retryCount + 1)
                    .then(resolve)
                    .catch(reject);
                } else {
                  setTimeout(() => {
                    attemptLoad(retryCount + 1)
                      .then(resolve)
                      .catch(reject);
                  }, RETRY_DELAY);
                }
              } else {
                reject(
                  new Error(
                    `Failed to load video after ${MAX_RETRIES} retries`,
                  ),
                );
              }
            };

            video.src = blobUrl;
          } else {
            const img = new Image();
            img.onload = () => resolve(blobUrl);
            img.onerror = () => {
              URL.revokeObjectURL(blobUrl);
              if (retryCount < MAX_RETRIES) {
                setTimeout(() => {
                  attemptLoad(retryCount + 1)
                    .then(resolve)
                    .catch(reject);
                }, RETRY_DELAY);
              } else {
                reject(new Error("Failed to load image after retries"));
              }
            };
            img.src = blobUrl;
          }
        })
        .catch((err) => {
          if (retryCount < MAX_RETRIES) {
            setTimeout(() => {
              attemptLoad(retryCount + 1)
                .then(resolve)
                .catch(reject);
            }, RETRY_DELAY);
          } else {
            reject(err);
          }
        });
    });
  };

  return attemptLoad();
};

function LoadMedia({
  alt,
  src,
  thumbnail,
  mimeType,
  className,
  showVideoIndicator = false,
  hideVideoControls = false,
}: LoadMediaProps) {
  const isVideo = React.useMemo(
    () => videoTypes.includes(mimeType),
    [mimeType],
  );

  // Enhanced error handlers with retry logic
  const handleVideoError = React.useCallback(
    (e: React.SyntheticEvent<HTMLVideoElement>) => {
      const video = e.currentTarget;

      // Try switching to MP4 format if MOV fails
      if (video.src.endsWith(".mov")) {
        const mp4Source = video.src.replace(".mov", ".mp4");
        video.src = mp4Source;
        return;
      }

      // Try switching to WebM format if MP4 fails
      if (video.src.endsWith(".mp4")) {
        const webmSource = video.src.replace(".mp4", ".webm");
        video.src = webmSource;
        return;
      }

      // If thumbnail exists, display it instead of fallback
      if (thumbnail) {
        video.poster = thumbnail;
        video.style.display = "none";
        return;
      }

      // Last resort: show fallback
      video.src = FALLBACK_SOURCES.video;
    },
    [thumbnail],
  );

  const handleImageError = React.useCallback(
    (e: React.SyntheticEvent<HTMLImageElement>) => {
      // Try thumbnail first if available
      if (thumbnail && e.currentTarget.src !== thumbnail) {
        e.currentTarget.src = thumbnail;
        return;
      }

      // Last resort: show fallback
      e.currentTarget.src = FALLBACK_SOURCES.image;
    },
    [thumbnail],
  );

  // Add ref to access video element
  const videoRef = React.useRef<HTMLVideoElement>(null);

  // Setup intersection observer
  const { ref: inViewRef, inView } = useInView({
    threshold: 0.1,
    triggerOnce: true,
  });

  // Query for media loading with enhanced error handling
  const { data: loadedSrc, isLoading } = useQuery({
    queryKey: ["media", src],
    queryFn: () => preloadMedia(src, isVideo),
    enabled: inView,
    staleTime: Infinity,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    retry: 3,
    retryDelay: 1000,
  });

  // Render loading placeholder when not in view or loading
  if (!inView || isLoading) {
    return (
      <div
        ref={inViewRef}
        className={cn(
          "relative h-full min-h-56 w-full animate-pulse rounded-md bg-primary/10",
          className,
        )}
      />
    );
  }

  return (
    <>
      {isVideo ? (
        <video
          ref={videoRef}
          className={cn(
            "relative h-full w-full rounded-md bg-muted object-cover",
            className,
          )}
          autoPlay={!isSafari}
          loop={showVideoIndicator}
          playsInline
          muted
          controls={!showVideoIndicator && !hideVideoControls}
          crossOrigin="anonymous"
          poster={thumbnail}
          onError={handleVideoError}
          preload="metadata"
        >
          <source src={loadedSrc} />
          <source src={loadedSrc} type="video/quicktime" />
          <source src={loadedSrc} type="video/mov" />
          <source src={loadedSrc} type="video/mp4" />
          <source src={loadedSrc} type="video/webm" />
          <p>Your browser doesn&apos;t support HTML video.</p>
        </video>
      ) : (
        <img
          alt={alt}
          src={loadedSrc ?? thumbnail}
          className={cn(
            "relative h-full w-full rounded-md bg-muted object-cover",
            className,
          )}
          crossOrigin="anonymous"
          loading="lazy"
          decoding="async"
          onError={handleImageError}
        />
      )}

      {showVideoIndicator && (
        <span
          className={cn(
            "absolute flex min-h-6 min-w-6 items-center justify-center gap-2 rounded-md bg-secondary-foreground/40 p-1",
            isVideo ? "right-2 top-2" : "bottom-2 left-1/2 -translate-x-1/2",
          )}
        >
          <Video
            className="relative h-4 w-4 shrink-0 text-accent"
            aria-hidden="true"
          />
          {!isVideo && (
            <span className="text-[10px] leading-none text-white">
              Preview unavailable.
            </span>
          )}
        </span>
      )}
    </>
  );
}

// Consider using memo if parent often re-renders with same props
export default React.memo(LoadMedia);
