import React, { useState, useCallback } from "react";
import { Check, Copy } from "lucide-react";
// import { toast } from '~/src/primitives/use-toast';

interface IColorSwatchProps {
  hexCode: string;
}

const ColorSwatch: React.FC<IColorSwatchProps> = ({ hexCode }) => {
  const [copied, setCopied] = useState(false);

  const handleCopy = useCallback(async () => {
    try {
      await navigator.clipboard.writeText(hexCode);
      setCopied(true);
      // toast({
      //   title: 'Copied!',
      //   description: `${hexCode} copied to clipboard.`,
      // });
      setTimeout(() => setCopied(false), 2000);
    } catch (error) {
      // toast({
      //   title: 'Error',
      //   description: 'Failed to copy to clipboard.',
      //   variant: 'destructive',
      // });
    }
  }, [hexCode]);

  return (
    <button
      onClick={handleCopy}
      className="inline-flex items-center gap-1 rounded-md bg-background p-0.5 text-sm font-medium shadow-fl transition hover:bg-muted"
    >
      <span
        className="inline-block h-4 w-4 rounded border"
        style={{ backgroundColor: hexCode }}
      />
      <span>{hexCode}</span>
      {copied ? (
        <Check className="size-3.5 text-green-500" />
      ) : (
        <Copy className="size-3.5 text-muted-foreground" />
      )}
    </button>
  );
};

export default ColorSwatch;
