import React, { useContext, useEffect, useState } from "react";
import API from "~/src/api/withApi";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
} from "~/src/primitives/dialog";
import { useDispatch } from "react-redux";
import { Input } from "~/src/primitives/input";
import { Button } from "~/src/primitives/button";
import { ArrowRight, Trash2, User, UserCheck } from "lucide-react";
import { UserAccount } from "~/src/api/auth.api";
import { username, withPfp } from "~/src/util/reusables";
import UserSearch from "./UserSearch";
import { OrganizationContext } from "../../Organization";
import { Separator } from "~/src/primitives/separator";
import { ApprovalWorkflow } from "~/src/api/organization.api";
import Selector from "~/src/partials/Tagger/Selector";
import { Team } from "~/src/api/team.api";
import { setUser } from "~/src/reducers/user";
import { setLoading } from "~/src/reducers/toolkit";

interface UpdateApprovalWorkflowModalProps {
  show: boolean;
  approvalWorkflow: ApprovalWorkflow;
  onUpdate: (
    approvalWorkflowId: string,
    diff: Partial<ApprovalWorkflow>,
  ) => void;
  onHide: () => void;
}

const UpdateApprovalWorkflowModal = ({
  show,
  approvalWorkflow,
  onUpdate,
  onHide,
}: UpdateApprovalWorkflowModalProps) => {
  const dispatch = useDispatch();
  const organization = useContext(OrganizationContext);
  const [name, setName] = useState<string>("");
  const [approvers, setApprovers] = useState<Partial<UserAccount>[]>([]);
  const [appliedWorkspaces, setAppliedWorkspaces] = useState<Partial<Team>[]>();

  const onApplyApprovalWorkflowToWorkspace = (
    workspaceId: string,
    approvalWorkflowId: string,
  ) =>
    API.applyApprovalWorkflowToWorkspace(workspaceId, approvalWorkflowId)
      .then(() => API.getSession("?update=true"))
      .then(({ user }) => dispatch(setUser(user)));

  const onRemoveApprovalWorkflowFromWorkspace = (workspaceId: string) =>
    API.removeApprovalWorkflowFromWorkspace(workspaceId)
      .then(() => API.getSession("?update=true"))
      .then(({ user }) => dispatch(setUser(user)));

  useEffect(() => {
    if (approvalWorkflow) {
      setName(approvalWorkflow.name);
      setApprovers(approvalWorkflow.approvers as UserAccount[]);
    }
    if (organization?.workspaces && approvalWorkflow) {
      const appliedWorkspaces = (
        organization.workspaces as Partial<Team>[]
      ).filter((e) => e.approvalWorkflow === approvalWorkflow._id);
      setAppliedWorkspaces(appliedWorkspaces);
    }
  }, [approvalWorkflow, organization, show]);

  return (
    <Dialog open={show} onOpenChange={(open) => !open && onHide()}>
      <DialogContent className="max-h-[90vh] overflow-y-auto">
        <DialogHeader>
          <div className="flex flex-row items-center justify-center">
            <div>
              <UserCheck className="mr-2 h-4 w-4" />
            </div>
            <div>
              Editing &lsquo;{approvalWorkflow?.name}&lsquo; Approval Workflow
            </div>
          </div>
        </DialogHeader>
        <div>
          <div className="flex flex-row items-center justify-start gap-4">
            <div className="font-bold">Name</div>
            <div>
              <Input
                value={name}
                placeholder="My Approval Workflow"
                spellCheck={false}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
          </div>

          <Separator className="my-6" />

          <div className="flex flex-col gap-4">
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-2 font-bold">
                Flow{" "}
                <span className="text-xs text-muted-foreground">
                  ({approvers.length} selected)
                </span>
              </div>
              <UserSearch
                seats={organization?.seats}
                selected={approvers?.map((e) => e._id)}
                onSelect={(user) => {
                  const tmp = [...approvers];
                  tmp.push(user);
                  setApprovers(tmp);
                }}
                onDeselect={(user) => {
                  let tmp = [...approvers];
                  tmp = tmp.filter((e) => e._id !== user._id);
                  setApprovers(tmp);
                }}
              >
                <Button size="xs" className="rounded-full">
                  <User className="mr-1 size-3" />
                  Add Approver
                </Button>
              </UserSearch>
            </div>

            <div className="max-h-[300px] overflow-y-auto rounded-lg border">
              {approvers?.map((user, index) => (
                <div key={user._id}>
                  <div className="flex items-center gap-3 p-3">
                    <img
                      src={withPfp(user?.pfp, username(user))}
                      crossOrigin="anonymous"
                      className="h-10 w-10 rounded-full border shadow-sm"
                    />
                    <div className="flex flex-1 flex-col">
                      <span className="font-medium">{username(user)}</span>
                      {user.email && (
                        <span className="text-xs text-muted-foreground">
                          {user.email}
                        </span>
                      )}
                    </div>
                    <Button
                      variant="ghost"
                      size="icon"
                      className="h-8 w-8"
                      onClick={() => {
                        const tmp = approvers.filter((a) => a._id !== user._id);
                        setApprovers(tmp);
                      }}
                    >
                      <Trash2 className="h-4 w-4 text-muted-foreground" />
                    </Button>
                  </div>
                  {index < approvers.length - 1 && (
                    <div className="flex justify-center py-2">
                      <div className="flex h-6 w-6 items-center justify-center rounded-full bg-muted">
                        <ArrowRight className="h-4 w-4 rotate-90 text-muted-foreground" />
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>

          <Separator className="my-6" />

          {appliedWorkspaces && (
            <div className="space-y-2">
              <div className="text-sm font-semibold">Enforced Workspaces</div>
              <div className="flex flex-row items-center gap-4">
                <Selector
                  options={(organization.workspaces as Partial<Team>[]).map(
                    (e) => e._id,
                  )}
                  current={appliedWorkspaces.map((e) => e._id)}
                  mutator={(_id) =>
                    (organization.workspaces as Partial<Team>[]).find(
                      (e) => e._id === _id,
                    )?.name
                  }
                  onChange={(_id) => {
                    const awsp = appliedWorkspaces.map((e) => e._id);
                    if (awsp.includes(_id)) {
                      dispatch(setLoading("Updating workspace..."));
                      onRemoveApprovalWorkflowFromWorkspace(_id).finally(() =>
                        dispatch(setLoading(undefined)),
                      );
                    } else {
                      dispatch(setLoading("Updating workspace..."));
                      onApplyApprovalWorkflowToWorkspace(
                        _id,
                        approvalWorkflow._id,
                      ).finally(() => dispatch(setLoading(undefined)));
                    }
                  }}
                />
              </div>
            </div>
          )}
        </div>
        <DialogFooter>
          <Button variant="outline" size="sm" onClick={onHide}>
            Close
          </Button>
          <Button
            variant="default"
            size="sm"
            disabled={!name}
            onClick={() => {
              const diff: Partial<ApprovalWorkflow> = {
                name,
                approvers: approvers.map((e) => e._id),
              };

              onHide();
              onUpdate(approvalWorkflow?._id, diff);
            }}
          >
            Save
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default UpdateApprovalWorkflowModal;
