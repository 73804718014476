import { PlatformType } from "../util/platforms";
import axios from "axios";
import { z } from "zod";
import { Team } from "./team.api";

export const locationSchema = z.object({
  name: z.string().min(1, "Location name is required"),
  admin_name: z.string().min(1, "Admin name is required"),
  admin_email: z.string().email("Invalid email address"),
  address: z.string().min(1, "Address is required"),
  city: z.string().min(1, "City is required"),
  state: z.string().min(1, "State is required"),
  zip: z.string().regex(/^\d{5}(-\d{4})?$/, "Invalid ZIP code"),
  contact_email: z
    .string()
    .email("Invalid email address")
    .optional()
    .or(z.literal("")),
  phone_number: z.string().regex(/^\d{10}$/, "Invalid phone number"),
  website_url: z.string().url("Invalid URL").optional().or(z.literal("")),
  hours: z.object({
    sunday: z.object({
      is_open: z.boolean(),
      open: z.string(),
      close: z.string(),
    }),
    monday: z.object({
      is_open: z.boolean(),
      open: z.string(),
      close: z.string(),
    }),
    tuesday: z.object({
      is_open: z.boolean(),
      open: z.string(),
      close: z.string(),
    }),
    wednesday: z.object({
      is_open: z.boolean(),
      open: z.string(),
      close: z.string(),
    }),
    thursday: z.object({
      is_open: z.boolean(),
      open: z.string(),
      close: z.string(),
    }),
    friday: z.object({
      is_open: z.boolean(),
      open: z.string(),
      close: z.string(),
    }),
    saturday: z.object({
      is_open: z.boolean(),
      open: z.string(),
      close: z.string(),
    }),
  }),
  time_zone: z.string().min(1, "Time zone is required"),
});
export type LocationFormData = z.infer<typeof locationSchema>;

export type LocationAccountInfo = {
  organizationId: string;
  organizationName: string;
  platform: PlatformType;
  platformAccountName: string;
  platformAccountPfp: string;
  platformAccountId: string;
  supportedPlatformContentTypes: string[];
  teamId: string;
  teamName: string;
  location: LocationFormData;
};

const getLocationsInfo = async (
  locationIds: string[],
): Promise<LocationAccountInfo[][]> => {
  const params = new URLSearchParams();
  locationIds.forEach((lid) => params.append("locations", lid));

  const response = await axios.get<LocationAccountInfo[][]>(
    "/api/locations/accounts",
    { params },
  );
  return response.data;
};

export type Location = Partial<LocationFormData> & {
  _id: string;
  customFields?: Record<string, any>;
};
export const upsertLocation = async (
  teamId: string,
  locationData: Partial<Location>,
  finishedOnboarding?: boolean,
) => {
  const response = await axios.patch<Location>(
    `/api/locations/${teamId}`,
    locationData,
    {
      params: {
        finishedOnboarding,
      },
    },
  );
  return response.data;
};

const createNewLocation = async (
  organizationId: string,
  locationData: LocationFormData,
  hubId?: string,
  requiresOnboarding?: boolean,
  skipInvite?: boolean,
) => {
  const response = await axios.post<{ team: string; location: string }>(
    `/api/locations/${organizationId}/new`,
    locationData,
    {
      params: {
        hubId,
        requiresOnboarding,
        skipInvite,
      },
    },
  );
  return response.data;
};

const getTeamLocation = async (
  teamId: string,
): Promise<{ _id: string; name: string; location: Location }> => {
  const response = await axios.get<{
    _id: string;
    name: string;
    location: Location;
  }>(`/api/locations/team/${teamId}`);
  return response.data;
};

const inviteLocationAdmin = async (teamId: string) => {
  const response = await axios.post<{ message: string }>(
    `/api/locations/${teamId}/invite`,
  );
  return response.data;
};

export const getHubLocationOrganizations = async () => {
  const response = await axios.get<
    {
      _id: string;
      name: string;
      owner_email: string;
    }[]
  >(`/api/locations/hub/organizations`);
  return response.data;
};

export const buildAddressForWorkspace = (workspace?: Team) => {
  const addressLine = workspace?.location?.address || "";
  const city = workspace?.location?.city || "";
  const state = workspace?.location?.state || "";
  const zip = workspace?.location?.zip || "";
  const address = `${addressLine}${addressLine ? ", " : ""}${city}${
    city ? ", " : ""
  }${state}${state && zip ? " " : ""}${zip}`.trim();
  return { address, name: workspace?.name, _id: workspace?._id };
};

export interface LocationsAPI {
  getLocationsInfo: (teamIds: string[]) => Promise<LocationAccountInfo[][]>;
  createNewLocation: (
    organizationId: string,
    locationData: LocationFormData,
    hubId?: string,
    requiresOnboarding?: boolean,
    skipInvite?: boolean,
  ) => Promise<{
    team: string;
    location: string;
  }>;
  getTeamLocation: (teamId: string) => Promise<{
    _id: string;
    name: string;
    location: Location;
  }>;
  inviteLocationAdmin: (teamId: string) => Promise<{
    message: string;
  }>;
}

export default {
  getLocationsInfo,
  createNewLocation,
  getTeamLocation,
  inviteLocationAdmin,
} as LocationsAPI;

/**
 * Converts a variable name to a human-readable label
 *
 * @param variable - The variable name to convert
 * @returns The human-readable label for the variable
 */
export const variableToLabel = (variable: string): string => {
  return variable
    .replace(/_/g, " ")
    .replace(/\b\w/g, (char) => char.toUpperCase());
};

/**
 * List of all supported dynamic variables for location-based content
 */
export const dynamicVariables = [
  "location_name",
  "organization_name",
  "address",
  "city",
  "state",
  "zip",
  "contact_email",
  "phone_number",
  "website_url",
  "hours",
] as const;

/**
 * Type representing valid dynamic variable names
 */
export type DynamicVariable = (typeof dynamicVariables)[number];

/**
 * Maps dynamic variables to their accessor functions for retrieving values from a LocationAccountInfo object
 */
export const dynamicVariableAccessors: Record<
  DynamicVariable,
  (account: LocationAccountInfo) => string
> = {
  location_name: (account) => account?.teamName || "",
  organization_name: (account) => account?.organizationName || "",
  address: (account) => account?.location?.address || "",
  city: (account) => account?.location?.city || "",
  state: (account) => account?.location?.state || "",
  zip: (account) => account?.location?.zip || "",
  contact_email: (account) => account?.location?.contact_email || "",
  phone_number: (account) => formatPhoneNumber(account?.location?.phone_number),
  website_url: (account) => account?.location?.website_url || "",
  hours: (account) => formatOpeningHours(account?.location?.hours),
};

/**
 * Gets all dynamic variable values for a specific account
 *
 * @param account - The account to extract variable values from
 * @returns A record mapping variable names to their values
 */
export const getDynamicVariableValues = (
  account?: LocationAccountInfo,
): Record<DynamicVariable, string> => {
  if (!account) return {} as Record<DynamicVariable, string>;

  return dynamicVariables.reduce<Record<DynamicVariable, string>>(
    (values, variable) => {
      values[variable] = dynamicVariableAccessors[variable](account);
      return values;
    },
    {} as Record<DynamicVariable, string>,
  );
};

/**
 * Interface representing a node in the rich text editor content
 */
interface ContentNode {
  type: string;
  attrs?: {
    variable?: DynamicVariable;
    [key: string]: any;
  };
  text?: string;
  content?: ContentNode[];
  [key: string]: any;
}

/**
 * Analyzes a template string to find which dynamic variables are being used
 *
 * @param template - The template string to analyze (can be a JSON string or plain text)
 * @returns An array of dynamic variable names that are used in the template
 */
export const getDynamicVariablesInUse = (
  template: string,
): DynamicVariable[] => {
  if (!template) return [];

  try {
    // Try to parse as JSON (for rich text editor content)
    const content = JSON.parse(template) as ContentNode;
    const usedVariables = new Set<DynamicVariable>();

    // Recursive function to find variables in the content structure
    const findVariablesInNode = (node: ContentNode): void => {
      if (node.type === "variable" && node.attrs?.variable) {
        if (dynamicVariables.includes(node.attrs.variable as DynamicVariable)) {
          usedVariables.add(node.attrs.variable as DynamicVariable);
        }
      }

      // Process content array if it exists
      if (Array.isArray(node.content)) {
        node.content.forEach((child) => {
          findVariablesInNode(child);
        });
      }
    };

    findVariablesInNode(content);
    return Array.from(usedVariables);
  } catch (e) {
    // If not valid JSON, check for variable patterns in plain text
    const usedVariables = new Set<DynamicVariable>();

    // Look for patterns like {{variable_name}} in the text
    const variablePattern = /\{\{(.*?)\}\}/g;
    let match;

    while ((match = variablePattern.exec(template)) !== null) {
      const variableName = match[1].trim() as DynamicVariable;
      if (dynamicVariables.includes(variableName)) {
        usedVariables.add(variableName);
      }
    }

    return Array.from(usedVariables);
  }
};

/**
 * Replaces dynamic variables in a template with their actual values
 *
 * @param template - The template string containing variables to replace
 * @param account - The account containing the values for the variables
 * @returns The template with all variables replaced with their values
 */
export const replaceDynamicVariables = (
  template: string,
  account?: LocationAccountInfo,
): string => {
  if (!template) return "";
  if (!account) return template;

  const variableValues = getDynamicVariableValues(account);

  try {
    const content = JSON.parse(template) as ContentNode;

    // Deep clone the content to avoid mutating the original
    const processedContent = JSON.parse(JSON.stringify(content)) as ContentNode;

    // Recursive function to process all nodes
    const processNode = (node: ContentNode): ContentNode => {
      if (node.type === "variable" && node.attrs?.variable) {
        if (!variableValues[node.attrs.variable]) {
          return;
        }
        // Replace variable node with text node containing the replacement value
        return {
          type: "text",
          text: variableValues[node.attrs.variable] || "*not available*",
        };
      }

      // Process content array if it exists
      if (Array.isArray(node.content)) {
        node.content = node.content.map((child) => {
          return processNode(child);
        });
      }

      return node;
    };

    // Process the entire content structure
    const result = processNode(processedContent);

    return JSON.stringify(result);
  } catch (error) {
    // Fallback to simple string replacement if not valid JSON
    return template.replace(/{{\s*(\w+)\s*}}/g, (_, variable) => {
      return variableValues[variable as DynamicVariable] || "";
    });
  }
};

const formatOpeningHours = (hours: Record<string, any>): string => {
  if (!hours) return "Hours not available";
  return Object.entries(hours)
    .map(
      ([day, { is_open, open, close }]) =>
        `${capitalize(day)}: ${is_open ? `${open} - ${close}` : "Closed"}`,
    )
    .join(", ");
};

const formatPhoneNumber = (phone: string | undefined): string => {
  if (!phone) return "";
  return phone.replace(/(\\d{3})(\\d{3})(\\d{4})/, "($1) $2-$3");
};

const capitalize = (str: string): string =>
  str.charAt(0).toUpperCase() + str.slice(1);
