import React, { useEffect, useMemo } from "react";

import {
  useEditor,
  EditorContent,
  ReactRenderer,
  JSONContent,
  Content,
} from "@tiptap/react";
import Document from "@tiptap/extension-document";
import Paragraph from "@tiptap/extension-paragraph";
import Text from "@tiptap/extension-text";
import History from "@tiptap/extension-history";
import Placeholder from "@tiptap/extension-placeholder";
import Mention from "@tiptap/extension-mention";
import { SuggestionOptions } from "@tiptap/suggestion";
import tippy from "tippy.js";
import "tippy.js/themes/translucent.css";

import { PlatformType } from "../../../../util/platforms";
import { IPlatformAccountTag } from "../../../../api/engage.api";
import {
  captionToPlainText,
  characterLimits,
  getPlatformWithLowestCharacterLimit,
} from "../../../../util/share";
import { cn, NewSocialToLogo, TooltipButton } from "../../../../util/reusables";
import CaptionToolbar from "../CaptionToolbar";
import { Button } from "../../../../primitives/button";
import { TriangleAlert, Undo } from "lucide-react";
import { Hashtag } from "../../../../partials/TextEditor/extensions/hashtag";
import { Variable } from "../../../../partials/TextEditor/extensions/variable";
import AccountsSearch from "../AccountsSearch";
import {
  LocationAccountInfo,
  replaceDynamicVariables,
} from "../../../../api/locations.api";

export type CaptionEditorProps = {
  content: Content;
  className?: string;
  placeholder?: string;
  platform?: PlatformType;
  selectedPlatforms?: PlatformType[];
  isPlatformDetatched?: boolean;
  attatchedPlatforms?: PlatformType[];
  viewOnly?: boolean;
  hideStatusBadge?: boolean;
  onSave?: (caption: string) => void;
  onReset?: () => void;
  account?: LocationAccountInfo;
};

export default function CaptionEditor({
  content,
  className,
  platform,
  viewOnly,

  placeholder = "Write a caption here...",
  selectedPlatforms,
  isPlatformDetatched,
  attatchedPlatforms,
  onReset,
  onSave,
  hideStatusBadge,
  account,
}: CaptionEditorProps) {
  const limit = useMemo(
    () =>
      characterLimits?.[platform] ??
      characterLimits?.[
        getPlatformWithLowestCharacterLimit(attatchedPlatforms)
      ],
    [attatchedPlatforms, platform],
  );
  const suggestion = useMemo(() => getSuggestions(platform), [platform]);

  const extensions = useMemo(
    () => [
      ...captionExtensions({ viewOnly, suggestion }),
      ...(!viewOnly
        ? [
            Placeholder.configure({
              placeholder,
            }),
          ]
        : []),
    ],
    [viewOnly, suggestion, placeholder, limit],
  );

  const editor = useEditor(
    {
      extensions,
      content: parseDynamicContent(content, account),
      editable: !viewOnly,
    },
    [limit, suggestion, content, platform, account],
  );

  useEffect(() => {
    return () => {
      if (editor) {
        editor.destroy();
      }
    };
  }, [editor]);

  const percentage = editor
    ? Math.round(
        (100 / limit) *
          captionToPlainText(JSON.stringify(editor?.getJSON()))?.length,
      )
    : 0;

  if (viewOnly) {
    if (!editor?.state?.doc?.textContent?.trim()?.length)
      return (
        <span
          className={cn(
            "flex h-20 w-full items-center justify-center gap-1 rounded-lg border-2 border-dashed bg-background text-xs text-gray-400",
            className,
          )}
        >
          <TriangleAlert className="size-3" />
          This caption is empty
        </span>
      );

    return <EditorContent className={className} editor={editor} />;
  }

  return (
    <>
      <div className="relative">
        <EditorContent
          onClick={() => editor?.view?.focus()}
          className={className}
          editor={editor}
        />

        {/* Caption Status */}
        {!hideStatusBadge && (
          <div className="absolute bottom-1 left-1 flex gap-2">
            {/* Indicator */}
            <div
              className={cn(
                "flex w-fit items-center justify-center gap-1 rounded-full border bg-muted px-2 text-2xs font-semibold shadow-sm",
                isPlatformDetatched &&
                  selectedPlatforms?.length !== 1 &&
                  "bg-fl-firebush text-white",
                platform &&
                  selectedPlatforms?.length !== 1 &&
                  "border-fl-firebush",
              )}
            >
              {platform && selectedPlatforms?.length !== 1
                ? isPlatformDetatched
                  ? "Editing overridden caption for"
                  : "Overriding caption for"
                : "Editing caption for "}
              {platform ? (
                <NewSocialToLogo
                  platform={platform}
                  className="ring ring-inset ring-white"
                />
              ) : (
                <div className="ml-1 flex">
                  {[...new Set(attatchedPlatforms || [])]?.map((pl) => (
                    <NewSocialToLogo
                      key={`${pl}-caption`}
                      className="-ml-1 ring ring-inset ring-white"
                      platform={pl}
                    />
                  ))}
                </div>
              )}
            </div>

            {/* Reset Detatched Caption? */}
            {isPlatformDetatched && selectedPlatforms?.length !== 1 && (
              <TooltipButton text="Reset overwritten content back to initial caption?">
                <Button
                  variant="destructive"
                  size="tiny"
                  className="flex items-center gap-1 text-2xs"
                  onClick={() => onReset?.()}
                >
                  <Undo className="size-3" />
                  Reset Caption
                </Button>
              </TooltipButton>
            )}
          </div>
        )}

        {/* Character counter */}
        <div
          className={cn(
            "abosolute absolute bottom-1 right-1 flex items-center gap-2 text-2xs text-secondary-foreground",
            captionToPlainText(JSON.stringify(editor?.getJSON()))?.length >=
              limit && "text-destructive",
          )}
        >
          {captionToPlainText(JSON.stringify(editor?.getJSON()))?.length ?? 0} /{" "}
          {limit} characters
          <svg className="size-4" viewBox="0 0 20 20">
            <circle r="10" cx="10" cy="10" fill="#e9ecef" />
            <circle
              r="5"
              cx="10"
              cy="10"
              fill="transparent"
              stroke="currentColor"
              strokeWidth="10"
              strokeDasharray={`calc(${percentage} * 31.4 / 100) 31.4`}
              transform="rotate(-90) translate(-20)"
            />
            <circle r="6" cx="10" cy="10" fill="white" />
          </svg>
        </div>
      </div>
      <div className="flex justify-between gap-8 pt-2">
        {/* Toolbar */}
        <CaptionToolbar
          onInsert={(content: JSONContent) => {
            editor?.chain()?.focus()?.insertContent(content).run();
          }}
          disabled={{
            mentions: !platform
              ? "Please select a platform above..."
              : undefined,
            variables: undefined,
            translate: "Translate coming soon...",
          }}
          platform={platform}
        />

        {/* Manual Actions */}
        <Button
          size="xs"
          disabled={
            captionToPlainText(JSON.stringify(editor?.getJSON()))?.length >
            limit
          }
          onClick={() => onSave?.(JSON.stringify(editor.getJSON()))}
        >
          Save
        </Button>
      </div>
    </>
  );
}

// Utils
export const captionExtensions = ({
  suggestion = { char: "@" },
  viewOnly,
}: {
  suggestion?: Omit<SuggestionOptions<any>, "editor">;
  viewOnly?: boolean;
}) => [
  Document,
  Paragraph,
  Text,
  History,
  Mention.configure({
    HTMLAttributes: {
      class: cn("text-accent-blue", !viewOnly && "bg-blue-50"),
    },
    suggestion,
  }),
  Hashtag.configure({
    HTMLAttributes: {
      class: cn("text-accent-blue", !viewOnly && "bg-blue-50"),
    },
  }),
  Variable.configure({
    HTMLAttributes: {
      class: cn("text-green-600", !viewOnly && "bg-green-50"),
    },
  }),
];

/**
 * Gets suggestions for @ commands in the Caption Editor
 * @returns the `<AccountSearch />` component allowing users to add mentions inline
 */
const getSuggestions = (
  platform: PlatformType,
): Omit<SuggestionOptions, "editor"> => {
  if (!platform) return {};

  return {
    char: "@",
    render: () => {
      let component: any;
      let popup: any;

      return {
        onStart: ({ editor, command, clientRect }) => {
          component = new ReactRenderer(AccountsSearch, {
            props: {
              className: "border shadow",
              platform,
              onSelectAccount: (account: IPlatformAccountTag) => {
                command(accountToMentionContent(account, platform, true));
              },
            },
            editor: editor,
          });

          if (!clientRect) {
            return;
          }

          popup = tippy("body", {
            theme: "translucent",
            getReferenceClientRect: clientRect,
            appendTo: () => document.body,
            content: component.element,
            showOnCreate: true,
            interactive: true,
            trigger: "manual",
            placement: "bottom-start",
            onShow: (instance) => {
              // Focus the AccountsSearch component after a short delay
              setTimeout(() => {
                const inputElement = instance.popper.querySelector("input");
                if (inputElement) {
                  inputElement.focus();
                }
              }, 0);
            },
          });
        },

        onUpdate(props) {
          component.updateProps(props);

          if (!props.clientRect) {
            return;
          }

          popup[0].setProps({
            getReferenceClientRect: props.clientRect,
          });
        },

        onKeyDown(props) {
          if (props.event.key === "Escape") {
            popup[0].hide();

            return true;
          }
        },

        onExit() {
          if (popup[0]) popup[0].destroy();
          if (component) component.destroy();
        },
      };
    },
  };
};

export const initializeStringToTextContent = (text: string) => ({
  type: "doc",
  content: [
    {
      type: "paragraph",
      ...(text
        ? {
            content: [
              {
                type: "text",
                text: text,
              },
            ],
          }
        : {}),
    },
  ],
});

export const serializePlainTextCaption = (text: string) => {
  const content = initializeStringToTextContent(text);
  return JSON.stringify(content);
};

export const stringToTextContent = (text: string) =>
  text
    ? {
        type: "text",
        text: text,
      }
    : undefined;

export const accountToMentionContent = (
  account: IPlatformAccountTag,
  platform: PlatformType,
  onlyAttrs?: boolean,
) => {
  if (!account || !platform) return;

  let id = "";
  let label: string | undefined = undefined;

  switch (platform) {
    case PlatformType.Instagram:
      id = account.platformAccountUsername;
      label = account.platformAccountUsername;
      break;
    case PlatformType.Twitter:
      id = account.platformAccountId;
      label = account.platformAccountUsername;
      break;
    case PlatformType.Facebook:
    case PlatformType.LinkedIn:
      id = account.platformAccountId;
      label = account.platformAccountName ?? account.platformAccountUsername;
      break;
  }

  if (onlyAttrs) return { id, label };

  return {
    type: "mention",
    attrs: { id, label },
  };
};

export const variableContent = (variable: string) => ({
  type: "variable",
  attrs: { variable },
});

export const parseTiptapContent = (content: string) => {
  let c: JSONContent;
  try {
    c = JSON.parse(content);
  } catch {
    c = initializeStringToTextContent(content);
  }

  return c;
};

const parseDynamicContent = (
  content: Content,
  account?: LocationAccountInfo,
): Content => {
  if (!account) return content;

  const jsonString = JSON.stringify(content);
  const replacedString = replaceDynamicVariables(jsonString, account);

  try {
    return JSON.parse(replacedString);
  } catch {
    return content;
  }
};
